import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

export default function GetInTouch() {
  const navigate = useNavigate();
  const [form, setForm] = React.useState({
    namalengkap: "",
    email: "",
    pesan: "",
  });

  
  const [error, setError] = useState('');

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setForm((state) => ({ ...state, [name]: value }));
    
    if (!value.trim()) {
      setError('This field is required.');
    } else {
      setError('');
    }
  };
  const [isProcessing, setIsProcessing] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!error) {
      setIsProcessing(true);
      try {
        const response = await axios.post("https://landing.pikkat.com/get_intouch", {
          namalengkap: form.namalengkap,
          divisi: 'Operational',
          email: form.email,
          pesan: form.pesan,
        });
        if(response.data.status==='success'){
          Swal.fire({
            title: "Terimaksih Atas partisipasinya, Kami akan segera membalas pertanyaan Anda",
            icon: "success",
            confirmButtonColor: "#07214E",
          }).then(navigate("/"));
          // Handle successful login here
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
            confirmButtonColor: "#07214E",
          });
        }
                
      } catch (error) {
        
        Swal.fire({
          title: "Maaf..Terjadi Masalah saat mengirim pesan, silahkan coba lagi",
          icon: "error",
        });
      } finally {
        setIsProcessing(false);
      }
    }
  };

  return (
    <div className="bg-pikkat-green/[5%] flex flex-row justify-center py-20 items-center">
      <div className="container flex justify-center">
        <div className="bg-white rounded-xl lg:min-w-[68%] w-[90%] lg:w-[68%] lg:p-10 p-4 shadow-lg">
          <h1 className="md:text-3xl text-xl font-semibold">
            Pertanyaan Seputar <span className="text-pikkat-green">Mitra Toko</span>
          </h1>
          <p className="light-text text-lg pt-3">
          Ajukan pertanyaan, Kami akan siap membantu Anda menjadi bagian dari Mitra Pikkat.
          </p>

          <div className="flex flex-col-reverse md:flex-row gap-5 md:gap-0 pt-8 text-sm">
            <div className="md:w-1/3 flex flex-col  pr-20 justify-between">
              <div className="flex flex-col gap-3">
                <p className="flex flex-row gap-2">
                  <span>
                    <img
                      src={require("../../assets/icons/phone.png")}
                      alt="phone"
                    />
                  </span>
                  0817 7922 7733
                </p>
                <p className="flex flex-row gap-2">
                  <span>
                    <img
                      src={require("../../assets/icons/envelope2.png")}
                      alt="mail"
                    />
                  </span>
                  info@pikkat.com
                </p>
              </div>
              <div className="flex flex-col text-lg font-semibold pt-4 md:pt-0">
                <p>Connect with us</p>
                <div className="flex flex-row gap-2 pt-1 scale-90">
                  <a
                    href="https://www.instagram.com/pikkat.store/"
                    target="blank"
                  >
                    <img
                      src={require("../../assets/icons/instagram-2.png")}
                      alt="social media"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/pikkat.store/"
                    target="blank"
                  >
                    <img
                      src={require("../../assets/icons/facebook-2.png")}
                      alt="social media"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/@pikkat-store/videos"
                    target="blank"
                  >
                    <img
                      src={require("../../assets/icons/youtube-2.png")}
                      alt="social media"
                    />
                  </a>
                  <a href="https://twitter.com/PikkatStore" target="blank">
                    <img
                      src={require("../../assets/icons/twitter-2.png")}
                      alt="social media"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="md:w-3/5 flex flex-col gap-2 flex-2 text-sm">
              <div>
                <p className="pb-2 font-medium">Nama</p>
                <input
                  value={form.namalengkap}
                  name="namalengkap"
                  onChange={handleChangeInput}
                  className="border min-w-full min-h-40 rounded-lg px-4 ml-[3px]"
                  placeholder="nama"
                />
              </div>
              <div>
                <p className="pb-2 font-medium">Email</p>
                <input
                  value={form.email}
                  name="email"
                  onChange={handleChangeInput}
                  className="border min-w-full min-h-40 rounded-lg px-4 ml-[3px]"
                  placeholder="e-mail"
                  autoComplete="off"
                />
              </div>
              <div>
                <p className="pb-2 font-medium">Ajukan Pertanyaan</p>
                <textarea
                  value={form.pesan}
                  name="pesan"
                  onChange={handleChangeInput}
                  className="border min-w-full min-h-[150px] rounded-lg p-4 ml-[3px]"
                  placeholder="pertanyaan"
                ></textarea>
              </div>
              <button
                type="button"
                className={
                    isProcessing
                    ? " cursor-wait pik-btn w-[100%]"
                    : "font-semibold text-white bg-pikkat-green px-16 py-2 rounded-lg my-2"
                }
                onClick={handleLogin}
                disabled={isProcessing}
               >
                Kirim
               </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
