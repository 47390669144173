import Intro from "../components/LandingPage/intro.js";
import Benefits from "../components/LandingPage/benefits.js";
import MitraPemasok from "../components/LandingPage/mitra-pemasok.js";
import Achievements from "../components/LandingPage/achievements.js";
import Testimony from "../components/LandingPage/testimony.js";
import FAQ from "../components/LandingPage/faq-landing.js";
import GetInTouch from "../components/LandingPage/get-in-touch.js";

export default function LandingPage() {
  return (
    <>
      <Intro />
      <Benefits />
      <MitraPemasok />
      <Achievements />
      <Testimony />
      <FAQ />
      <GetInTouch />
    </>
  );
}
