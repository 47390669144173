import Icon1 from "../../assets/icons/layanan-icon1.png";
import Icon2 from "../../assets/icons/layanan-icon2.png";
import Icon3 from "../../assets/icons/layanan-icon3.png";

const Component2 = () => {
  const Images = [Icon1, Icon3, Icon2, Icon3];
  const title = [" Visibilitas Merek yang meningkat","Jangkauan Pasar yang luas","Distribusi yang efektif","Peluang pertumbuhan"];
  const conten = [
    "Pemasok dapat meningkatkan eksposur merek mereka dan memiliki peluang untuk menampilkan produk mereka di lingkungan ritel yang dikelola dengan baik dan ramai pengunjung.",
    "Pemasok mendapatkan akses langsung ke basis pelanggan yang besar dan beragam melalui lebih dari 20.000 jaringan toko ritel Pikkat.",
    "Pikkat memiliki solusi distribusi yang disesuaikan dengan kebutuhan spesifik setiap pemasok, memastikan produk didistribusikan secara efisien dan efektif ke pasar yang tepat.",
    "Pikkat terus berinovasi di sektor ritel, memberikan peluang bagi pemasok untuk memperluas pasar mereka, meluncurkan produk baru, dan tetap unggul dalam persaingan yang dinamis."
  ];
  return (
    <div className=" bg-gradient-to-tr from-pikkat-green/10 via-pikkat-green/5 to-pikkat-green/1 to-[50%] py-12 lg:py-24 items-center flex justify-center">
      <div className="container">
        <div className="flex flex-col items-center justify-center space-y-3 pb-8">
          <h1 className="lg:text-left text-center text-[24px] lg:text-[32px] font-semibold">
            Keuntungan Menjadi{" "}
            <span className="text-pikkat-green">Mitra Pemasok</span>
          </h1>
          <p className="lg:w-[70%] px-2 lg:px-0 text-center text-lg text-pikkat-gray-2 ">
          Keuntungan besar dengan bergabung sebagai Mitra Pemasok, memenuhi kebutuhan ribuan para Mitra Toko
          </p>
        </div>
        <div className="flex flex-row overflow-x-scroll lg:overflow-visible justify-evenly gap-6">
          {Images.map((image, index) => (
            <div key={index} className="bg-white border-2 border-transparent hover:shadow-xl hover:border-pikkat-green hover:scale-105 p-4 shadow-lg flex flex-col justify-center text-center items-center gap-10 rounded-xl duration-200 lg:px-12 lg:py-40 min-w-[280px]">
            <img src={image} alt="layanan" className="h-[60px] w-[60px]" />
            <h2 className="font-semibold lg:text-2xl">{title[index]}</h2>
            <p className="light-text text-md">
              {conten[index]}
            </p>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Component2;
