import { useNavigate } from "react-router-dom";

const SideCardsCard = ({ article, index }) => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div
      className="grid grid-flow-col grid-cols-11 items-center min-h-[100px] hover:cursor-pointer py-2"
      onClick={() => navigateTo(`/detail-berita/${article.id}`)}
    >
      <div className="col-span-2">
        <img
          src={article.images}
          alt="berita card"
          className="max-h-[100px] object-cover rounded-xl"
        />
      </div>
      <div className="col-span-9 flex flex-col gap-[1px] px-4 text-justify leading-tight">
        <h3 className="text-sm lg:text-md font-semibold">
          {article.title}
        </h3>
        <p className="text-[10px] text-pikkat-gray-2">
          {article.content.slice(0,100)}. . .
        </p>
        <div className="flex flex-row gap-2 text-[8px] text-pikkat-gray-2/80 items-center ">
          <p>{article.admin}</p>
          <img
            src={require("../../assets/icons/dot.png")}
            alt="just dot"
            className="max-h-[6px] w-auto self-center"
          />
          <p>{article.updated.slice(0,10)}</p>
          <button className=" text-orange-400 font-semibold border-[1.5px] border-orange-400 bg-orange-200/30 p-1 px-2 rounded-lg">
            {article.categori}
          </button>
        </div>
      </div>
    </div>
  );
};

const SideCards = ({articles}) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg">      
      <div className="bg-white/90  px-3 py-4 rounded-lg">
        <p className=" text-blue-400 font-semibold text- flex flex-row items-center gap-2 pb-4">
          <span>
            <img
              src={require("../../assets/icons/red-dot.png")}
              alt="red dot"
            />
          </span>
          Top Post
        </p>
        {articles.slice(0,5).map((article, index) => (
          <SideCardsCard article={article} index={index} key={index}/>
        ))}
      </div>
    </div>
  );
};

export default SideCards;
