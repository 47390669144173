export default function Services() {
  return (
    <div className="bg-gradient-to-bl from-pikkat-green/10 from-5% via-35% stop-95% via-white to-pikkat-green/10 justify-center flex px-2 md:px-0">
      <div className="container w-screen mx-auto py-8">
        <div className="lg:grid grid-cols-12 gap-4 mt-8 lg:mt-[60px]">
          <div className="col-span-6 object-contain relative flex flex-col justify-center mb-4">
            <img
              src={require("../../assets/layanan-pikkat.png")}
              alt="tujuan"
              className=""
            />
          </div>
          <div className="col-span-6 px-4 lg:px-12 grid gap-2">
            <h1 className="text-[24px] md:text-[32px] font-semibold text-pikkat-black text-center">
              Layanan
              <span className="text-pikkat-green"> Pikkat</span>
            </h1>
            <p className="text-pikkat-gray-2/80 font-medium text-md text-center pb-6">
            Pikkat berkomitmen memberikan pelayanan terbaik kebutuhan perkembangan usaha warung dalam satu aplikasi
            </p>

            <div className="flex flex-col gap-3">
              <div className="bg-white flex flex-row p-2 rounded-2xl hover:translate-x-8 duration-200 hover:bg-pikkat-green
              text-pikkat-gray
              hover:text-white items-center">
                <img
                  src={require("../../assets/services-icon1.png")}
                  alt="circle"
                  className="size-[50px] lg:size-[80px]"
                />
                <div className="p-2 py-3 lg:p-4 lg:py-6 shadow-sm">
                  <p className="font-semibold pb-2">Aplikasi POS dan Dashboard</p>
                  <p className="lg:text-sm text-xs">
                  Aplikasi pengelolaan toko yang handal dan memadai untuk beragam transaksi dan pencatatan keuangan.
                  </p>
                </div>
              </div>
              <div className="bg-white flex flex-row p-2 rounded-2xl hover:translate-x-8 duration-200 hover:bg-pikkat-green
              text-pikkat-gray
              hover:text-white items-center ">
                <img
                  src={require("../../assets/services-icon2.png")}
                  alt="circle"
                  className="size-[90px] lg:size-[120px]"
                />
                <div className="p-2 py-3 lg:p-4 lg:py-6 shadow-sm">
                  <p className="font-semibold pb-2">Akses Produk</p>
                  <p className="lg:text-sm text-xs">
                  Pikkat menyediakan kebutuhan produk bagi warung dengan beragam variasi produk dari supplier terbaik dengan harga yang kompetitif.
                  </p>
                </div>
              </div>
              <div className="bg-white flex flex-row p-2 rounded-2xl hover:translate-x-8 duration-200 hover:bg-pikkat-green
              text-pikkat-gray
              hover:text-white items-center ">
                <img
                  src={require("../../assets/services-icon3.png")}
                  alt="circle"
                  className="size-[50px] lg:size-[80px]"
                />
                <div className="p-2 py-3 lg:p-4 lg:py-6 shadow-sm">
                  <p className="font-semibold pb-2">Akses Permodalan</p>
                  <p className="lg:text-sm text-xs">
                  Pikkat memberikan kesempatan kepada warung untuk mendapatkan permodalan dari perusahaan pembiayaan yang bekerja sama dengan Pikkat.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
