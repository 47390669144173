'use client'

import TentangKamiIntro from "../components/TentangKami/tentang-kami-intro";
import VisiMisi from "../components/TentangKami/visi-misi";
import PrinsipKami from "../components/TentangKami/prinsip-kami";
// import {
//   APIProvider,
//   Map,
//   AdvancedMarker,
//   Pin,
//   InfoWindow,
// } from "@vis.gl/react-google-maps";
// import { useState } from "react";

const TentangKami = () => {
  // const position = {lat: 53.54, lng: 10};
  // const [open, setOpen] = useState(false)
  return (
    <div>
      <TentangKamiIntro />
      <VisiMisi />
      <PrinsipKami />
      <div className="flex flex-col justify-center items-center lg:w-[100%] self-center">
          <h1 className="font-semibold text-3xl py-6">
            <span className="primary-color">Jangkaun </span> Kami
          </h1>
          <p className="text-center text-lg text-slate-500">
          Pikkat telah menjangkau berbagai wilayah kota besar di sekitar anda.
          </p>
        </div>
      <img src={require("../assets/map.png")} alt="peta" className="py-8" />
      {/* <APIProvider apiKey="process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY">
        <Map zoom={9} center={position} mapId={process.env.NEXT_PUBLIC_MAP_ID}></Map>
      </APIProvider> */}
    </div>
  );
};

export default TentangKami;
