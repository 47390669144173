import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();

  // const login = (e) => {
  //   e.preventDefault();
  //   navigate("/")
  // };
  return (
    <div className="bg-gradient-to-br from-[20%] via-[30%] to-[90%] from-pikkat-green/5 via-white to-pikkat-green/10 pb-16 min-w-screen min-h-screen items-center flex justify-center">
      <div className="container">
        <div className="flex flex-col text-center items-center gap-7 md:px-36">
          <h1 className="text-xl md:text-2xl font-bold">
            Jadilah Bagian dari <span className="text-pikkat-green">Pikkat</span>
          </h1>
          <p className="md:px-20 text-sm text-pikkat-gray">
          
          </p>
        </div>
        <div className="flex flex-col items-center lg:flex-row gap-16 md:gap-8 justify-center mt-16">
          <div className="benefits-card hover:border-3 hover:border-pikkat-green">
            <div className="justify-center items-center">
              <img
                src={require("../assets/icons/member.png")}
                className="absolute -top-12 -translate-x-[50%] left-[50%]"
                alt="circle"
              />
              <h2>Member</h2>
              <p className="text-md md:text-md text-pikkat-gray/90">
              Belanja kebutuhan lebih mudah dan hemat.
              </p>
            </div>
            <div className="justify-center items-center">
                <button className="font-semibold text-white bg-pikkat-green px-16 py-2 rounded-lg my-2"
                onClick={() => {
                  navigate("/daftar-member");
                }}
                >
                Daftar
                </button>
                <p className="text-pikkat-gray-2 text-nowrap text-md pt-2 pb-4">
                Sudah punya akun?{" "}
                <span className="text-pikkat-red cursor-pointer">
                    Login
                </span>
                </p>
            </div>
          </div>
          <div className="benefits-card">
          <div className="justify-center items-center">
            <img
                src={require("../assets/icons/toko.png")}
                className="absolute -top-12 -translate-x-[50%] left-[50%]"
                alt="circle"
              />
              <h2>Mitra Toko</h2>
              <p className="text-md md:text-md text-pikkat-gray/90">
              Tingkatkan penjualan Toko Anda Sekarang.
              </p>
            </div>            
            <div className="justify-center items-center">
                <button className="font-semibold text-white bg-pikkat-green px-16 py-2 rounded-lg my-2"
                onClick={() => {
                  navigate("/daftar-mitra-toko");
                }}
                >
                Daftar
                </button>
                <p className="text-pikkat-gray-2 text-nowrap text-md pt-2 pb-4">
                Sudah punya akun?{" "}
                <span className="text-pikkat-red cursor-pointer">
                    Login
                </span>
                </p>
            </div>
          </div>
          <div className="benefits-card">
                <img
                src={require("../assets/icons/pemasok.png")}
                className="absolute -top-12 -translate-x-[50%] left-[50%]"
                alt="circle"
                />
            <div className="justify-center items-center">
                <h2>Pemasok</h2>
                <p className="text-md lg:text-md text-pikkat-gray/90">
                Ribuan Toko siap membeli produk Anda.
                </p>
            </div>
            
            <div className="justify-center items-center">
                <button className="font-semibold text-white bg-pikkat-green px-16 py-2 rounded-lg my-2"
                onClick={() => {
                  navigate("/daftar-mitra-pemasok");
                }}
                >
                Daftar
                </button>
                <p className="text-pikkat-gray-2 text-nowrap text-md pt-2 pb-4">
                Sudah punya akun?{" "}
                <span className="text-pikkat-red cursor-pointer">
                    Login
                </span>
                </p>
            </div>
            
          </div>         
        </div>
      </div>      
    </div>
  );
};

export default LoginPage;
