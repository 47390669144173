import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";
import "../../assets/style/swiper-custom.css";
import axios from "axios";
import base_url from "../../constants/global";


const titles = ["Belanja","Hemat ","Terdekat", "Cepat","Cepat","Cepat"];

export default function Intro() {
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const [data, setData] = useState({})

  useEffect(() => {
    axios.post(`${base_url}/home_page`)
      .then(response => {
        setData(response.data.banner);
      })
      .catch(error => {
        console.error('Error fetching the posts:', error);
      });
  }, []);
  

  const [profileData, setProfileData] = React.useState([]);

  React.useEffect(() => {
    setProfileData(data);
  }, []);
  
  
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % titles.length);
        setFade(true);
      }, 600);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const swiper = document.querySelector(".mySwiper").swiper;
    swiper.params.navigation.prevEl = ".custom-prev";
    swiper.params.navigation.nextEl = ".custom-next";
    swiper.navigation.init();
    swiper.navigation.update();
  }, [profileData]);

 
  
  
  return (
    <div className="md:pt-24 bg-gradient-to-br from-10% via-60% stop-90% from-pikkat-green/10 to-pikkat-green/10 via-white flex justify-center px-3">
      {data.length > 0 ? (

      
      <div className="flex flex-col items-center md:grid grid-flow-col grid-cols-12 container mx-auto">
        <div className="col-span-6 flex flex-col justify-between">
          <div
            className={`fade-${
              fade ? "in" : "out"
            } col-span-6 flex flex-col  gap-7 py-4 text-left pt-[5em]`}
          >
            <h1 key={data.index} className="text-3xl md:text-5xl font-semibold xl:pr-24">{data[index].title}</h1>
            <img
              src={require("../../assets/curved-line.png")}
              className="w-[359px]"
              alt="intro"
            />
            <p className="font-medium text-pikkat-gray-2/90 md:text-xl text-justify xl:pr-32">
              {data[index].description}
            </p>
          </div>
          {/* <a className="bg-pikkat-red text-pikkat-white rounded-xl text-[13px] max-w-[200px] p-3 flex flex-row items-center justify-evenly" href={data[index].link_url} target="blank">
          Belanja Sekarang
              <img
                src={require("../../assets/icons/arrow-up-right-1.png")}
                alt="up right arrow icon"
              />
            </a> */}
        </div>
        <div className="relative max-h-[400px] object-contain col-span-6 flex flex-col justify-center max-w-[320px] md:max-w-max">
          <img
            src={require("../../assets/intro-background.png")}
            alt="intro"
            className="scale-[90%] hidden md:block object-cover absolute translate-x-12"
          />
          <div className="z-40 scale-75">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              freeMode={false}
              navigation={{
                prevEl: ".custom-prev",
                nextEl: ".custom-next",
              }}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination, Navigation]}
              className="mySwiper bg-cover swiper"
            >
              {data.length !== 0 &&
                data.map((item) => {
                  return (
                    <SwiperSlide>
                      <div key={item.id}
                        className={`bg-[${item.bgColor}] flex justify-center items-center rounded-lg group md:m-8`}
                      >
                        <img
                          src={item.images}
                          alt="item"
                          className="rounded-2xl h-[450px] object-cover"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
          
        </div>
      </div>
      ):(<div>No Data</div>)}
    </div>
  );
}
