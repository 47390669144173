import pin from "../assets/icons/map-pin.svg";
import clock from "../assets/icons/clock.svg";

export default function Footer() {
  return (
    <footer className="bg-pikkat-gray min-h-[482px] text-pikkat-white-2 flex flex-col justify-between py-[81px] px-[12px] lg:px-[100px]">
      <div className="flex flex-col lg:flex-row justify-around">
        <div className="flex-1 flex flex-col gap-5 font-extralight text-pikkat-white-2/70 items-center ">
          <img
            src={require("../assets/logo.png")}
            alt="logo"
            className="w-[150px]  max-h-[60px]"
          />
          <div className="flex-1 flex flex-col gap-2 items-center">
          <p className="font-semibold text-pikkat-white-2 text-nowrap pt-8 lg:pt-0">Our Social media</p>
          <div className="flex flex-row gap-2">
            <a href="https://www.facebook.com/pikkat.store/" target="blank">
              <img
                src={require("../assets/icons/facebook.png")}
                alt="facebook icon"
              />
            </a>
            <a href="https://www.instagram.com/pikkat.store/" target="blank">
              <img
                src={require("../assets/icons/instagram.png")}
                alt="instragram icon"
              />
            </a>
            <a
              href="https://www.youtube.com/@pikkat-store/videos"
              target="blank"
            >
              <img
                src={require("../assets/icons/youtube.png")}
                alt="youtube icon"
              />
            </a>
            <a href="https://twitter.com/PikkatStore" target="blank">
              <img
                src={require("../assets/icons/twitter.png")}
                alt="twitter icon"
              />
            </a>
          </div>
        </div>
        </div>
        <div className="flex-1 flex flex-row py-8 lg:py-0">
          <div className="flex-1 flex flex-col items-center">
            <div>
              <p className="font-semibold text-lg mb-2 lg:mb-8">Company</p>
              <div className="font-extralight text-pikkat-white-2/70">
                <a href="/about" >
                  <p>Tentang Kami</p>
                </a>
                <a href="/berita" >
                  <p>Blog & Berita</p>
                </a>
                <a href="/dukungan" >
                  <p>FAQ</p>
                </a>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-left">
            <div>
              <p className="font-semibold text-lg mb-2 lg:mb-8">Resources</p>
              <div className="font-extralight text-pikkat-white-2/70">
                <a href="/daftar-sekarang">
                  <p>Register Account</p>
                </a>
                <a href="/privacy">
                <p>Privacy & Policy</p>
                </a>                
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col font-extralight text-pikkat-white-2/70 gap-3">
          <div className="flex flex-row">
            <img src={pin} className="self-start" alt="map pin" />
            <div className="flex flex-col gap-1">
              <p className="font-semibold text-pikkat-white-2">Our Location</p>
              <p className="font-semibold text-pikkat-white-2/70"></p>
              <p className="text-sm">
                Setiabudi Atrium Lt. 4 Unit 406 Jl. H.R. Rasuna Sahid Kav. 62
                Kuningan, Jakarta Selatan 12920
              </p>
            </div>
          </div>
          <div className="flex flex-row">
            <img src={clock} className="self-start" alt="map pin" />
            <div className="flex flex-col gap-1">
              <p className="font-semibold text-pikkat-white-2">Office Hour</p>
              <p className="text-sm">Monday - Friday at 09.00 am - 05.00 pm</p>
            </div>
          </div>
        </div>
      </div>      
      <div className="flex flex-row justify-between">   
      <div className="flex-1"></div>   
        <div className="flex flex-col justify-end text-sm lg:text-md pt-24">
          ©2024 Pikkat. All rights reserved
        </div>   
        <div className="flex-1"></div> 
      </div>
    </footer>
  );
}
