import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();

  const login = (e) => {
    e.preventDefault();
    navigate("/")
  };
  return (
    <div className="bg-gradient-to-br from-[20%] via-[30%] to-[90%] from-pikkat-green/5 via-white to-pikkat-green/10 pb-16 min-w-screen min-h-screen items-center flex justify-center">
      <form
        className="bg-white min-w-[337px] min-h-[428px] max-w[540px] max-h-[555px] p-4 pt-20 rounded-xl items-center flex flex-col justify-center gap-3 relative"
        onSubmit={login}
      >
        <img
          src={require("../assets/icons/pikkat-logo2.png")}
          className="absolute top-0 -translate-y-16"
        />
        <h2 className="font-semibold">
          Selamat Datang di <span className="text-pikkat-green">Pikkat</span>
        </h2>
        <p className="text-center text-pikkat-gray-2">
          Silahkan masukkan alamat email dan kata sandi yang telah terdaftar
        </p>
        <label className="self-start">Email</label>
        <div className="border-pikkat-gray-2 rounded-lg border-[1.5px] px-3 w-full flex flex-row items-center py-2 ">
          <input
            type="email"
            placeholder="someone@mail.com"
            className=" focus:ring-0 outline-none w-full"
          />
          <img src={require("../assets/icons/envelope-2.png")} />
        </div>
        <label className="self-start">Password</label>
        <div className="border-pikkat-gray-2 rounded-lg border-[1.5px] px-3 w-full flex flex-row items-center py-2">
          <input
            type="password"
            placeholder="*****"
            className=" focus:ring-0 outline-none w-full"
          />
          <img src={require("../assets/icons/eye.png")} />
        </div>
        <p className="text-pikkat-red self-end cursor-pointer">
          Lupa Kata Sandi?
        </p>
        <button className="font-semibold text-white bg-pikkat-green px-16 py-2 rounded-lg my-2">
          Masuk
        </button>
        <p className="text-pikkat-gray-2 text-nowrap text-sm pt-2 pb-4">
          Belum memiliki akun?{" "}
          <span className="text-pikkat-green cursor-pointer">
            Daftar Sekarang
          </span>
        </p>
      </form>
    </div>
  );
};

export default LoginPage;
