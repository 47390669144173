export default function Benefits() {
  return (
    <div className="py-24 pt-36 bg-gradient-to-bl from-pikkat-green/10 from-5% via-35% stop-95% via-white to-pikkat-green/10 justify-center flex px-2 md:px-0">
      <div className="container">
        <div className="flex flex-col text-center items-center gap-7 md:px-36">
          <h1 className="text-xl md:text-2xl font-bold">
            Menjadi Mitra <span className="text-pikkat-green">Toko</span>
          </h1>
          <p className="md:px-20 text-md text-pikkat-gray">
          Berjualan di Pikkat lebih mudah dan menguntungkan. Nikmati layanan pikkat untuk memudahkan anda dalam memenuhi kebutuhan toko anda.
          </p>
          <button className="text-md px-2 shadow-md text-pikkat-green font-medium flex flex-row gap-2 items-center rounded-md">
            Pelajari
            <span>
              <img
                src={require("../../assets/icons/up-right-arrow.png")}
                alt="up right arrow"
              />
            </span>
          </button>
        </div>
        <div className="flex flex-col items-center lg:flex-row gap-16 md:gap-8 justify-center mt-16">
          <div className="benefits-card hover:border-3 hover:border-pikkat-green">
            <img
              src={require("../../assets/mitra-toko1.png")}
              className="absolute -top-12 -translate-x-[50%] left-[50%]"
              alt="circle"
            />
            <h2>Mudah</h2>
            <p className="text-md md:text-md text-pikkat-gray/90">
            Mudah dalam berjualan online. Persediaan toko lebih lengkap tanpa stock di toko.
            </p>
          </div>
          <div className="benefits-card">
            <img
              src={require("../../assets/mitra-toko2.png")}
              className="absolute -top-12 -translate-x-[50%] left-[50%]"
              alt="circle"
            />
            <h2>Hemat</h2>
            <p className="text-md md:text-md text-pikkat-gray/90">
            Hemat waktu dan tenaga dengan belanja kebutuhan barang di toko lebih mudah melalui aplikasi.
            </p>
          </div>
          <div className="benefits-card">
            <img
              src={require("../../assets/mitra-toko3.png")}
              className="absolute -top-12 -translate-x-[50%] left-[50%]"
              alt="circle"
            />
            <h2>Dekat</h2>
            <p className="text-md lg:text-md text-pikkat-gray/90">
            Supplyer barang lebih banyak tersedia dalam gengggaman aplikasi Pikkat.
            </p>
          </div>
          <div className="benefits-card">
            <img
              src={require("../../assets/mitra-toko4.png")}
              className="absolute -top-12 -translate-x-[50%] left-[50%]"
              alt="circle"
            />
            <h2>Sehat</h2>
            <p className="text-md lg:text-md text-pikkat-gray/90">
            Produk sayur dan buah yang fresh langsung dari petani.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
