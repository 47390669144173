import { useNavigate } from "react-router-dom";
import telegram from "../../assets/icons/telegram.png";
import whatsapp from "../../assets/icons/whatsapp.png";
import website from "../../assets/icons/website.png";

const titles = ["BOT Telegram", "BOT Whatsapp", " Marketplace Pikkat"];
const descriptions = [
  "Kamu bisa dengan mudah belanja apa saja melalui aplikasi Telegram.",
  "Lebih gampang berbelanja melalui aplikasi chating WhatsApp yang kamu punya.",
  "Dengan layanan lebih lengkap dan lebih mudah untuk belanja harian atau bulanan.",
];


const TentangKamiIntroCard = ({ image, index }) => {
  return (
    <div className="bg-white p-2 flex flex-row gap-8 rounded-2xl border-2 border-transparent hover:border-pikkat-green/80 duration-300">
      <div>
        <img src={image} alt="component2" className="h-[60px] max-w-[80px]" />
      </div>
      <div className="flex flex-col gap-2">
        <h2 className="text-xl font-semibold">{titles[index]}</h2>
        <p className="text-md text-pikkat-gray-2/80">{descriptions[index]}</p>
      </div>
    </div>
  );
};

const TentangKamiIntro = () => {
  const navigate = useNavigate();
  const ButtonToko = () => {
    return (
      <button className="border-2 border-white rounded-2xl mx-auto px-8 py-2 bg-black/20"
      onClick={() => {
        navigate("/daftar-mitra-toko");
      }}
      >
        Daftar sekarang
      </button>
    );
  };
  const ButtonMember = () => {
    return (
      <button className="border-2 border-white rounded-2xl mx-auto px-8 py-2 bg-black/20"
      onClick={() => {
        navigate("/daftar-mitra-pemasok");
      }}
      >
        Daftar sekarang
      </button>
    );
  };
  const images = [telegram, whatsapp, website];

  return (
    <div className="bg-gradient-to-br from-pikkat-green/10 via-white to-pikkat-green/20 via-[40%] to-[90%] flex justify-center items-center py-24 px-4 lg:px-0">
      <div className="container flex flex-col-reverse lg:grid grid-flow-col grid-cols-10">
        <div className=" col-span-4 lg:pr-8 flex flex-col gap-6 justify-center mt-8 lg:mt-0">
          <div className="relative group">
            <img
              src={require("../../assets/tentang-kami1.png")}
              alt="tentang kami"
              className="shadow-xl"
            />
           <div className="group-hover:hidden absolute top-0 left-0 w-full h-full bg-black/20 rounded-xl text-center items-center grid justify-center">
              <div className="flex flex-col gap-2 text-white">
                <p className="text-2xl font-semibold">Mitra</p>
              </div>
            </div>
            <div className="hidden group-hover:absolute top-0 left-0 w-full h-full bg-black/40 rounded-xl items-center group-hover:grid justify-center px-16 text-justify">
              <div className="flex flex-col gap-4 text-white">
                <p className="text-2xl font-semibold text-center">
                Gabung Sebagai Mitra
                </p>
                <p>
                  
                </p>
                <ButtonToko />
              </div>
            </div>
          </div>
          <div className="relative group">
            <img
              src={require("../../assets/tentang-kami2.png")}
              alt="tentang kami"
              className="shadow-xl"
            />
            <div className="group-hover:hidden absolute top-0 left-0 w-full h-full bg-black/20 rounded-xl text-center items-center grid justify-center">
              <div className="flex flex-col gap-2 text-white">
                <p className="text-2xl font-semibold">Pelanggan</p>
              </div>
            </div>
            <div className="hidden group-hover:absolute top-0 left-0 w-full h-full bg-black/40 rounded-xl items-center group-hover:grid justify-center px-16 text-justify">
              <div className="flex flex-col gap-4 text-white">
                <p className="text-2xl font-semibold text-center">
                Gabung Sebagai Pelanggan
                </p>
                <p>
                  
                </p>
                <ButtonMember />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-6 lg:pl-8">
          <div className="flex flex-col gap-6 items-center text-justify justify-center">
            <h1 className="font-bold text-4xl pb-6">
              Apa itu
              <span className="text-pikkat-green"> Pikkat</span>?
            </h1>
            <p className="text-pikkat-gray-2/80 font-medium">
            PIKKAT adalah platform manajemen bisnis terintegrasi yang ditujukan untuk usaha ritel skala kecil dan menengah di Indonesia. Platform ini menawarkan berbagai layanan termasuk sistem POS, manajemen inventaris, manajemen hubungan pelanggan, alat pemasaran, dan aksesibilitas pembiayaan. PIKKAT membantu bisnis UMKM mengelola operasional mereka dengan lebih efisien melalui integrasi berbagai fungsi, mempromosikan pertumbuhan, dan kemudahan penggunaan
            </p>
            <p className="self-start font-semibold text-lg">
            Saat ini Pikkat memiliki lebih dari 20 ribu database pedagang kecil, lebih dari 70 ribu data SKU dan dijalankan di beberapa kota di Indonesia.
            </p>
            <div className="flex flex-col gap-4">
              {images.map((image, index) => {
                return <TentangKamiIntroCard key={index} image={image} index={index} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TentangKamiIntro;
