import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../../constants/global";
import { CSSTransition } from "react-transition-group";
// import ReactPaginate from "react-paginate";


const FAQDropdownCard = ({
  answer,
  question,
  index,
  openIndex,
  setOpenIndex,
}) => {
  const handleClick = () => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div key={index}>
      <div
        className={
          openIndex === index
            ? "text-white bg-pikkat-green border-[0px]border-pikkat-green rounded-t-lg p-4 pb-0 text-md flex flex-row justify-between font-semibold cursor-pointer"
            : "text-pikkat-gray bg-white border-[1px] border-pikkat-gray-2/20 hover:border-pikkat-green rounded-lg p-4 text-md flex flex-row justify-between duration-[600ms]  cursor-pointer"
        }
        onClick={() => handleClick(index)}
      >
        <p>{question}</p>
        <span>
          <img
            src={require("../../assets/icons/down-arrow.png")}
            alt="just arrow"
            className={
              openIndex === index
                ? " rotate-0 duration-200"
                : "-rotate-90 duration-200"
            }
          />
        </span>
      </div>
      <CSSTransition
      key={index}
        in={openIndex === index}
        timeout={300}
        classNames="faq"
        unmountOnExit
      >
        <div className="-mt-1 p-4  bg-pikkat-green text-left text-white rounded-b-xl">
          <p className="border-white border-t-[0.8px] pt-4">{answer}</p>
        </div>
      </CSSTransition>
    </div>
  );
};

const FAQDropdown = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentItems, setCurrentItems] = useState(null);
  // const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;
  // const [currentPage, setCurrentPage] = useState(0);
  const [openIndex, setOpenIndex] = useState(null);
  const [categoryIndex, setCategoryIndex] = useState(0);

  useEffect(() => {
    axios
      .post(`${base_url}/all_faqs`)
      .then((response) => {
        const faqs = response.data.list_faq;
        setData(faqs);

        setFilteredData(faqs.filter((faq) => faq.categori === "Umum"))

        const uniqueCategories = [...new Set(faqs.map((faq) => faq.categori))];

        setCategories(uniqueCategories);
        setSelectedCategory("Umum")
      })
      .catch((error) => {
        console.error("Error fetching the posts:", error);
      });
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredData.slice(itemOffset, endOffset));
    // setPageCount(Math.ceil(filteredData.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredData, selectedCategory, openIndex]);



  const handleCategoryClick = (index, category) => {
    setCategoryIndex(index);
    setSelectedCategory(category);
    setOpenIndex(null);

    const newFilteredData = data.filter((faq) => faq.categori === category);
    setFilteredData(newFilteredData);

    // setCurrentPage(0);
    setItemOffset(0);
  };

  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % data.length;
  //   setItemOffset(newOffset);
  //   setCurrentPage(event.selected);
  // };

  return (
    <div className="bg-gradient-to-br from-pikkat-green/10 from-20% via-white to-pikkat-green/10 py-12">
      {data.length > 0 ? (
        <div className="container mx-auto">
          <div className="flex flex-col items-center justify-center space-y-3">
            <h1 className="text-[20px] md:text-[32px] font-semibold">
              Frequently{" "}
              <span className="text-pikkat-green">Asked Questions</span>
            </h1>
            <p className="lg:w-[80%] text-center text-pikkat-gray-2 lg:px-32 text-lg">
              Yang sering ditanyakan tentang Pikkat.
            </p>
          </div>
          <div className="lg:grid grid-flow-col grid-cols-10 gap-8 pt-8">
            <div className="col-span-2 hidden lg:flex lg:flex-col bg-white rounded-xl p-4 max-h-[360px] mb-12 lg:mb-0">
              <h2 className="hidden lg:block font-semibold pb-4 text-xl">
                Daftar Isi
              </h2>
              {categories.map((category, index) => (
                <div
                  key={index}
                  onClick={() => {
                    handleCategoryClick(index, category);
                  }}
                  className={
                    categoryIndex === index
                      ? "text-pikkat-green bg-pikkat-green/20 pl-6 pt-5 pb-3 border-b-[1.5px] border-pikkat-gray-2/20 font-medium text-md cursor-pointer"
                      : "text-pikkat-gray-2 pt-5 pb-3 border-b-[1.5px] border-pikkat-gray-2/20 font-medium text-md cursor-pointer"
                  }
                >
                  {category}
                </div>
              ))}
            </div>

            {/* Small Buttons */}
            <div className="lg:hidden flex flex-col gap-4 pb-5">
              <h2 className="pl-4 font-semibold">Daftar Isi</h2>
              <div className="flex flex-row gap-3 overflow-x-scroll no-scrollbar ml-3">
                {categories.map((category, index) => (
                  <button
                    key={index}
                    className={
                      categoryIndex === index
                        ? "bg-pikkat-green text-white rounded-lg px-2 py-1 border-pikkat-green"
                        : "border-2 rounded-lg px-2 py-1 border-pikkat-green text-pikkat-gray"
                    }
                    onClick={() => {
                      handleCategoryClick(index, category);
                    }}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>

            <div className="col-span-8 flex flex-col gap-4 mx-3 lg:mx-0">
              {currentItems
                .map((faq, index) => (
                  <FAQDropdownCard
                  key={index}
                  question={faq.question}
                    answer={faq.answer}
                    index={index}
                    setOpenIndex={setOpenIndex}
                    openIndex={openIndex}
                  />
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center min-w-full min-h-screen">
          <p>loading...</p>
          <img src={require("../../assets/icons/loading.gif")} alt="loading " style={{height:"90px"}}/>
        </div>
      )}
      
    </div>
  );
};

export default FAQDropdown;
