export default function Benefits() {
  return (
    <div className="py-24 bg-gradient-to-bl from-pikkat-green/10 from-5% via-35% stop-95% via-white to-pikkat-green/10 justify-center flex px-2 md:px-0">
      <div className="container">
        <div className="flex flex-col text-center items-center gap-7 pb-16 md:px-36">
          <h1 className="text-xl md:text-2xl font-bold">
            Keuntungan Mitra <span className="text-pikkat-green">Toko</span>
          </h1>
          <p className="md:px-20 text-lg text-pikkat-gray">
          Dapatkan peluang untuk meningkatkan keuntungan dan pendapatan, miliki daya saing layaknya toko modern, jangkau pelanggan lebih luas
          </p>
        </div>
        <div className="flex flex-col items-center lg:flex-row gap-16 md:gap-8 justify-center mt-16">
          <div className="benefits-card hover:border-3 hover:border-pikkat-green">
            <img
              src={require("../../assets/mitra-toko1.png")}
              className="absolute -top-12 -translate-x-[50%] left-[50%]"
              alt="circle"
            />
            <h2>Mudah</h2>
            <p className="text-sm md:text-sm text-pikkat-gray/90">
            Sekarang warung bisa berjualan lebih banyak barang untuk memenuhi kebutuhan pelanggan, sehingga dapat meningkatkan omzet dan keuntungan. Dan, warung tidak harus stock terlebih dahulu!
            </p>
          </div>
          <div className="benefits-card">
            <img
              src={require("../../assets/mitra-toko2.png")}
              className="absolute -top-12 -translate-x-[50%] left-[50%]"
              alt="circle"
            />
            <h2>Hemat</h2>
            <p className="text-sm md:text-sm text-pikkat-gray/90">
            Tidak perlu lagi harus datang ke distributor atau penyedia barang saat membeli barang persediaan. Cukup pesan melalui aplikasi POS Pikkat, dan barang akan diantar ke warung Anda.
            Hemat waktu dan biaya.
            </p>
          </div>
          <div className="benefits-card">
            <img
              src={require("../../assets/mitra-toko3.png")}
              className="absolute -top-12 -translate-x-[50%] left-[50%]"
              alt="circle"
            />
            <h2>Dekat</h2>
            <p className="text-sm lg:text-sm text-pikkat-gray/90">
            Jangkau pelanggan lebih banyak di area yang lebih luas. Dengan teknologi yang dimiliki Pikkat, warung dapat berjualan secara online dengan proses yang cukup mudah. Saatnya memberikan layanan yang lebih baik.
            </p>
          </div>
          <div className="benefits-card">
            <img
              src={require("../../assets/mitra-toko4.png")}
              className="absolute -top-12 -translate-x-[50%] left-[50%]"
              alt="circle"
            />
            <h2>Sehat</h2>
            <p className="text-sm lg:text-sm text-pikkat-gray/90">
            Kini warung dapat berjualan produk-produk segar seperti sayuran, buah-buahan, ayam potong dan ikan, dengan kualitas terbaik.
            Butuh peralatan penyimpanan? Pikkat punya solusinya.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
