import JadiMitraToko from "../components/MitraToko/menjadi-mitra-toko";
import Benefits from "../components/MitraToko/keuntungan-mitra-toko.js";
import Services from "../components/MitraToko/layanan-pikkat";
import FiturKami from "../components/MitraToko/fitur-kami";
import FaqMitra from "../components/MitraToko/faq-mitra-toko";
import GetInTouchMitra from "../components/MitraToko/get-in-touch.js";
const OurService = () => {
  return (
    <div className="w-full">
      <JadiMitraToko />
      <Benefits/>
      <Services />
      <FiturKami />
      <FaqMitra />
      <GetInTouchMitra />
    </div>
  );
};

export default OurService;
