import React, { useState } from 'react';

interface SectionProps {
  title: string;
  content: string;
}

const Section: React.FC<SectionProps> = ({ title, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <article className="flex flex-col mt-5 max-w-full w-[1484px]">
      
      <div className="flex flex-col p-6 w-full rounded-xl max-md:px-5 max-md:max-w-full text-pikkat-gray bg-white border-[1px] border-pikkat-gray-2/20 hover:border-pikkat-green rounded-lg p-4 text-sm flex flex-row justify-between duration-[600ms]  cursor-pointer">
        <button 
          className="text-lg font-semibold leading-none text-neutral-600 max-md:max-w-full flex flex-row justify-between  cursor-pointer"
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
        >
          <h2 className="self-stretch my-auto max-md:max-w-full">{title}</h2>
          <span>
          <img 
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC7SURBVHgB7ZGxDcMgEEUP7AEyQraw6DyKSxoQG4RsgJgkXVqnC1tkhGxAjsiKLATO0fOaQ3C8rwOATqdTxRhzovZKKc+1s6G0qbW2WO7TNEEI4QEHYO9lHMcb9jLsXfNzXrrEOX+nyhizSXAkTz1pHWN8AXWCJyKEYLicUTCXJtnLsV69944csIWstZBc7pyzNQ+DP+BnWxz/+0xY7SYlyUkBecjvIkGeGIDA/rla5M3gJItSaoFOp9PEB63vUPkWna+3AAAAAElFTkSuQmCC" 
          alt="just arrow" 
          class="-rotate-90 duration-200"
          />
          </span>
        </button>
        {isExpanded && (
          <div className="mt-5 text-base font-medium leading-6 text-neutral-600 max-md:max-w-full">
            {content}
          </div>
        )}
      </div>
      
            
    </article>
    
  );
};

export default Section;