import React from 'react';
import Section from '../components/Privacy/PolicySection';
import { sectionData } from '../components/Privacy/DefinitionList';

const PrivacyPolicy: React.FC = () => {
  return (
    <main className="flex flex-col items-center py-12">
      <header className="text-5xl font-semibold text-center  leading-[79px]">
        Privacy <span className="text-green-600">Policy</span>
      </header>
      <section className="mt-5 text-lg leading-7 text-stone-800 max-md:max-w-full px-12">
        Kebijakan Privasi
        <br />
        Terakhir diperbarui: 24 September 2024
        <br />
        <br />
        Kebijakan Privasi ini menjelaskan kebijakan dan prosedur Kami terkait pengumpulan, penggunaan, dan pengungkapan informasi Anda saat Anda menggunakan Layanan dan memberi tahu Anda tentang hak privasi Anda dan bagaimana hukum melindungi Anda.
        <br />
        <br />
        Kami menggunakan Data Pribadi Anda untuk menyediakan dan meningkatkan Layanan. Dengan menggunakan Layanan, Anda menyetujui pengumpulan dan penggunaan informasi sesuai dengan Kebijakan Privasi berikut ini.
      </section>      
      {sectionData.map((data, index) => (
        <Section key={index} title={data.title} content={data.content} />
      ))}
      
    </main>
    
  );
};

export default PrivacyPolicy;