import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavLinkButton from "./button/NavLinkButton";

const Navbar = () => {
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleButtonClick = (index, url) => {
    if (index === 1) {
      // Toggle dropdown visibility for "Layanan Kami" button
      setIsDropdownVisible(!isDropdownVisible);
      setSelectedButton(index);
    } else {
      setSelectedButton(index);
      setIsDropdownVisible(false); // Close dropdown if another button is clicked
      navigate(url);
      setIsSidebarOpen(false);
    }
  };

  const handleDropdownClick = (url) => {
    setSelectedButton(null);
    setIsDropdownVisible(false);
    setIsSidebarOpen(false);
    navigate(url);
  };

  const handleLoginButtonCLick = () => {
    setIsSidebarOpen(false);
    navigate("/login");
  };

  const handleDaftarButtonCLick = () => {
    setIsSidebarOpen(false);
    navigate("/daftar-sekarang");
  };

  const buttons = [
    {
      title: "Beranda",
      link: "/",
    },
    {
      title: "Layanan Kami",
      link: "/mitra-toko",
      icon: require("../assets/icons/down-red.png"),
    },
  ];

  const openSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <nav className="min-h-[120px] w-full flex items-center primary-gradient bg-white shadow-sm font-medium text-pikkat-gray-1 sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center w-full">
        <div className="">
          <img
            src={require("../assets/logo.png")}
            alt="logo"
            className="max-w-[150px] max-h-[60px] cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div className="hidden -mx-20 xl:m-0 lg:block">
          <div className="bg-pikkat-white flex items-center p-2 gap-3 rounded-lg relative scale-[80%] xl:scale-100">
            {buttons.map((label, idx) => (
              <div key={idx} className="relative">
                <NavLinkButton
                  label={label.title}
                  isSelected={selectedButton === idx}
                  customStyle={
                    idx === 1 && isDropdownVisible
                      ? "bg-red-500 text-white"
                      : ""
                  }
                  onClick={() => handleButtonClick(idx, label.link)}
                  icon={label.icon}
                />
                {idx === 1 && isDropdownVisible && (
                  <div className="absolute top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-md">
                    <ul>
                      <li
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"                        
                      >
                        E-Commerce
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleDropdownClick("/mitra-toko")}
                      >
                        Mitra Toko
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleDropdownClick("/mitra-pemasok")}
                      >
                        Mitra Pemasok
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="hidden lg:block">
          <div className="flex gap-3 justify-center items-center">
            <button className="bg-[#E62129] text-white py-2 px-4 text-nowrap rounded-md"
              onClick={() => {
                navigate("/daftar-sekarang");
              }}
            >
              Daftar Sekarang
            </button>
          </div>
        </div>

        {/* RESPONSIVE NAVBAR */}
        <div className="lg:hidden pr-2 cursor-pointer">
          <img
            src={require("../assets/icons/side-bar.png")}
            className="max-h-[50px]"
            onClick={openSidebar}
          />
          {isSidebarOpen && (
            <div className="fixed inset-0 bg-gray-800/50 z-40">
              <div className="fixed bg-white h-screen min-w-[50%] pl-2 right-0 top-0 z-50 animate-slideInFromRight overflow-y-scroll">
                <div className="flex flex-row pt-5">
                  <button className=" text-pikkat-red" onClick={openSidebar}>
                    <img src={require("../assets/icons/cross.png")} />
                  </button>
                </div>
                <div className="flex flex-col px-4 pt-4 gap-4">
                  {buttons.map((label, idx) => (
                    <div key={idx} className="relative">
                      <NavLinkButton
                        label={label.title}
                        isSelected={selectedButton === idx}
                        customStyle={
                          idx === 1 && isDropdownVisible
                            ? "bg-red-500 text-white"
                            : ""
                        }
                        onClick={() => handleButtonClick(idx, label.link)}
                        icon={label.icon}
                      />
                      {idx === 1 && isDropdownVisible && (
                        <div className=" top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-md">
                          <ul>
                            <li
                              className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            >
                              E-Commerce
                            </li>
                            <li
                              className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                              onClick={() =>
                                handleDropdownClick("/mitra-toko")
                              }
                            >
                              Mitra Toko
                            </li>
                            <li
                              className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                              onClick={() =>
                                handleDropdownClick("/mitra-pemasok")
                              }
                            >
                              Mitra Pemasok
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex flex-col gap-3 justify-center items-center pt-4">
                  <button className="bg-[#E62129] text-white py-2 px-4 text-nowrap rounded-md w-[80%]"
                    onClick={() => {
                      handleDaftarButtonCLick();
                    }}
                  >
                    Daftar Sekarang
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
