import Navbar from "./components/navbar.js";
import Footer from "./components/footer.js";
import { useRoutes } from "react-router-dom";
import router from "./routes/router.js";
import './assets/style/_main.scss'
import ScrollToTop from "./components/scrollToTop.js";

function App() {
  return (
    <div className="font-poppins w-full">
      <Navbar/>
      <ScrollToTop /> 
     {useRoutes(router)}

      <Footer />
    </div>
  );
}

export default App;
