import fitur from "../../assets/icons/fitur-icons.png";
import fitur1 from "../../assets/icons/fitur-icons1.png";
import fitur2 from "../../assets/icons/fitur-icons2.png";
import fitur3 from "../../assets/icons/fitur-icons3.png";
import fitur4 from "../../assets/icons/fitur-icons4.png";
import fitur5 from "../../assets/icons/fitur-icons5.png";

export default function FiturKami() {
  // const cards = [1, 2, 3, 4, 5, 6];
  const images = [fitur, fitur1, fitur2, fitur3, fitur4, fitur5];
  const titles = [
    "POS/Kasir",
    "Persediaan",
    "Pembelian Barang Pikkat",
    "Pelanggan",
    "Transaksi Online",
    "Laporan Operasional",
  ];
  const content = [
    "Mengelola transaksi penjualan dan penerimaan pembayaran di warung.",
    "Informasi stok barang warung secara real time, dan juga pengelolaan persediaan",
    "Permintaan pembelian barang ke Pikkat bisa dilakukan melalui POS dengan mudah.",
    "Pengelolaan data pelanggan untuk memberikan pelayanan yang lebih baik",
    "Untuk memudahkan warung dalam mengelola pemesanan barang secara online oleh pelanggan.",
    "Tersedia laporan operasional yang bisa diakses melalui POS maupun melalui dashboard",
  ]
  return (
    <div className="bg-white bg-gradient-to-tr from-pikkat-green/20 from-[5%] to-[50%] to-white min-h-[500px] lg:grid grid-cols-10 gap-2 p-4 lg:p-20">
      <div className=" col-span-6 flex flex-col gap-7">
        <h1 className="text-[24px] md:text-[32px] font-bold text-pikkat-green text-center">
          Fitur <span className="text-pikkat-black">Kami</span>
        </h1>
        <p className="text-pikkat-gray-2/80 font-medium text-md text-center pb-6">
        Pikkat memiliki berbagai macam fitur yang memudahkan Mitra dan pelanggan demi kelancaran transaksi para pelanggan di toko toko langganannya.
        </p>
        <div className="lg:grid grid-cols-3 gap-4">
          {images.map((image, index) => (
            <div key={index} className="bg-white/30 p-3 rounded-xl shadow-sm grid gap-3 border-[3.5px] border-transparent hover:border-pikkat-green/60 duration-[300ms] hover:scale-105">
              <div className="mb-2"><img src={image} className="z-10" alt="banyak-mitra" /></div>
              <h2 className="font-medium text-pikkat-black">
                {titles[index]}
              </h2>
              <p className="text-pikkat-gray/90 text-sm">
                {content[index]}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="col-span-4 hidden lg:flex flex-col justify-center">
        <img
          src={require("../../assets/icons/Left-fitur.png")}
          alt="fitur kami"
        />
      </div>
    </div>
  );
}
