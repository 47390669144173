export const sectionData = [
  
  {
    title: [<> <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p><p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Penafsiran dan Definisi
      </span>
    </p></>],
    content: [
      <> 
      <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>     
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Penafsiran
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Kata-kata yang huruf awalnya diawali dengan huruf kapital memiliki arti
        yang didefinisikan dalam kondisi berikut. Definisi berikut akan memiliki
        arti yang sama terlepas dari apakah kata tersebut muncul dalam bentuk
        tunggal atau jamak.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Definisi
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Untuk tujuan Kebijakan Privasi ini:
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Akun
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        {" "}
        berarti akun unik yang dibuat untuk Anda guna mengakses Layanan kami
        atau bagian dari Layanan kami.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Afiliasi {" "}
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        berarti suatu badan usaha yang mengendalikan, dikendalikan oleh atau
        berada di bawah kendali bersama dengan suatu pihak, di mana "kendali"
        berarti kepemilikan 50% atau lebih dari saham, kepentingan ekuitas atau
        sekuritas lain yang berhak memberikan suara untuk pemilihan direktur
        atau otoritas pengelola lainnya.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Aplikasi
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        {" "}
        mengacu pada Pikkat, program perangkat lunak yang disediakan oleh
        Perusahaan.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Perusahaan
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        (disebut sebagai "Perusahaan", "Kami", "Kita" atau "Milik Kami" dalam
        Perjanjian ini) mengacu pada Pikkat, Setiabudi Atrium Lt. 4 Unit 406 Jl.
        H.R. Rasuna Sahid Kav. 62 Kuningan, Jakarta Selatan 12920.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Cookie {" "}
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        adalah berkas kecil yang ditempatkan di komputer Anda, perangkat seluler
        atau perangkat lain apa pun oleh situs web, yang berisi rincian riwayat
        penelusuran Anda di situs web tersebut di antara banyak kegunaannya.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Negara
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        {" "}
        mengacu pada: Indonesia
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Perangkat
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        {" "}
        berarti perangkat apa pun yang dapat mengakses Layanan seperti komputer,
        ponsel atau tablet digital.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Data Pribadi
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        {" "}
        adalah informasi apa pun yang berkaitan dengan individu yang
        teridentifikasi atau dapat diidentifikasi.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Layanan
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        {" "}
        mengacu pada Aplikasi atau Situs Web atau keduanya.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Penyedia
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        {" "}
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Layanan {" "}
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        berarti setiap orang atau badan hukum yang memproses data atas nama
        Perusahaan. Ini mengacu pada perusahaan pihak ketiga atau individu yang
        dipekerjakan oleh Perusahaan untuk memfasilitasi Layanan, untuk
        menyediakan Layanan atas nama Perusahaan, untuk melakukan layanan yang
        terkait dengan Layanan atau untuk membantu Perusahaan dalam menganalisis
        bagaimana Layanan digunakan.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Data Penggunaan
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        mengacu pada data yang dikumpulkan secara otomatis, baik yang dihasilkan
        oleh penggunaan Layanan atau dari infrastruktur Layanan itu sendiri
        (misalnya, durasi kunjungan halaman).
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Situs Web
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        {" "}
        mengacu pada Pikkat, dapat diakses dari https://pikkat.com/
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Anda {" "}
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        berarti individu yang mengakses atau menggunakan Layanan, atau
        perusahaan, atau badan hukum lain yang atas nama individu tersebut
        mengakses atau menggunakan Layanan, sebagaimana berlaku.
      </span>
    </p>
      </>
     ]
  },
  {
    title:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Pengumpulan dan Penggunaan Data Pribadi Anda
      </span>
    </p></>],

    content:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Jenis Data yang Dikumpulkan
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Data Pribadi
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Saat menggunakan Layanan Kami, Kami dapat meminta Anda untuk memberikan
        Kami informasi pengenal pribadi tertentu yang dapat digunakan untuk
        menghubungi atau mengidentifikasi Anda. Informasi yang dapat
        mengidentifikasi pribadi dapat mencakup, tetapi tidak terbatas pada:
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Alamat email
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Alamat, Negara Bagian, Provinsi, Kode Pos, Kota
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Data Penggunaan
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Data Penggunaan
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Data Penggunaan dikumpulkan secara otomatis saat menggunakan Layanan.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Data Penggunaan dapat mencakup informasi seperti alamat Protokol
        Internet Perangkat Anda (misalnya alamat IP), jenis browser, versi
        browser, halaman Layanan kami yang Anda kunjungi, waktu dan tanggal
        kunjungan Anda, waktu yang dihabiskan di halaman tersebut, pengenal
        perangkat unik, dan data diagnostik lainnya.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Saat Anda mengakses Layanan melalui perangkat seluler, Kami dapat
        mengumpulkan informasi tertentu secara otomatis, termasuk, tetapi tidak
        terbatas pada, jenis perangkat seluler yang Anda gunakan, ID unik
        perangkat seluler Anda, alamat IP perangkat seluler Anda, sistem operasi
        seluler Anda, jenis browser Internet seluler yang Anda gunakan, pengenal
        perangkat unik, dan data diagnostik lainnya.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Kami juga dapat mengumpulkan informasi yang dikirim browser Anda setiap
        kali Anda mengunjungi Layanan kami atau saat Anda mengakses Layanan
        melalui perangkat seluler.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Informasi yang Dikumpulkan saat Menggunakan Aplikasi
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Saat menggunakan Aplikasi Kami, untuk menyediakan fitur Aplikasi Kami,
        Kami dapat mengumpulkan, dengan izin Anda sebelumnya:
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Informasi mengenai lokasi Anda
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Kami menggunakan informasi ini untuk menyediakan fitur Layanan Kami,
        untuk meningkatkan dan menyesuaikan Layanan Kami. Informasi tersebut
        dapat diunggah ke server Perusahaan dan/atau server Penyedia Layanan
        atau dapat disimpan di perangkat Anda.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Anda dapat mengaktifkan atau menonaktifkan akses ke informasi ini kapan
        saja, melalui pengaturan Perangkat Anda.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Teknologi Pelacakan dan Cookie
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Kami menggunakan Cookie dan teknologi pelacakan serupa untuk melacak
        aktivitas di Layanan Kami dan menyimpan informasi tertentu. Teknologi
        pelacakan yang digunakan adalah beacon, tag, dan skrip untuk
        mengumpulkan dan melacak informasi serta untuk meningkatkan dan
        menganalisis Layanan Kami. Teknologi yang Kami gunakan dapat mencakup:
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Cookie atau Cookie Peramban
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        . Cookie adalah berkas kecil yang ditempatkan di Perangkat Anda. Anda
        dapat memerintahkan peramban Anda untuk menolak semua Cookie atau untuk
        menunjukkan saat Cookie sedang dikirim. Namun, jika Anda tidak menerima
        Cookie, Anda mungkin tidak dapat menggunakan beberapa bagian Layanan
        kami. Kecuali Anda telah menyesuaikan pengaturan peramban Anda sehingga
        akan menolak Cookie, Layanan kami dapat menggunakan Cookie.
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Web Beacon
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        . Bagian tertentu dari Layanan kami dan email kami dapat berisi berkas
        elektronik kecil yang dikenal sebagai web beacon (juga disebut sebagai
        clear gif, pixel tag, dan single-pixel gif) yang mengizinkan Perusahaan,
        misalnya, untuk menghitung pengguna yang telah mengunjungi halaman
        tersebut atau membuka email dan untuk statistik situs web terkait
        lainnya (misalnya, merekam popularitas bagian tertentu dan memverifikasi
        integritas sistem dan server).
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Cookie dapat berupa Cookie "Persisten" atau Cookie "Sesi". Cookie
        Persisten tetap berada di komputer pribadi atau perangkat seluler Anda
        saat Anda offline, sedangkan Cookie Sesi dihapus segera setelah Anda
        menutup peramban web. Anda dapat mempelajari lebih lanjut tentang cookie
        di artikel situs web TermsFeed.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Kami menggunakan Cookie Sesi dan Cookie Persisten untuk tujuan yang
        ditetapkan di bawah ini:
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Cookie yang Diperlukan/Penting
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Jenis: Cookie Sesi
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Diatur oleh: Kami
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Tujuan: Cookie ini penting untuk menyediakan layanan yang tersedia
        melalui Situs Web dan memungkinkan Anda menggunakan beberapa fiturnya.
        Cookie ini membantu mengautentikasi pengguna dan mencegah penggunaan
        akun pengguna secara curang. Tanpa Cookie ini, layanan yang Anda minta
        tidak dapat diberikan, dan Kami hanya menggunakan Cookie ini untuk
        menyediakan layanan tersebut kepada Anda.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Kebijakan Cookie/Pemberitahuan Penerimaan Cookie
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <br />
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Jenis: Cookie Permanen
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <br />
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Diatur oleh: Kami
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <br />
      <br />
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Tujuan: Cookie ini mengidentifikasi apakah pengguna telah menerima
        penggunaan cookie di Situs Web.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Cookie Fungsionalitas
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <br />
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Jenis: Cookie Permanen
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <br />
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Diatur oleh: Kami
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <br />
      <br />
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Tujuan: Cookie ini memungkinkan kami mengingat pilihan yang Anda buat
        saat menggunakan Situs Web, seperti mengingat detail login atau
        preferensi bahasa Anda. Tujuan dari Cookie ini adalah untuk memberi Anda
        pengalaman yang lebih personal dan menghindari keharusan memasukkan
        kembali preferensi Anda setiap kali menggunakan Situs Web.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Untuk informasi lebih lanjut tentang cookie yang kami gunakan dan
        pilihan Anda terkait cookie, silakan kunjungi Kebijakan Cookie kami atau
        bagian Cookie pada Kebijakan Privasi kami.
      </span>
    </p></>]

  },
  {
    title:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Penggunaan Data Pribadi Anda
      </span>
    </p></>],

    content:[<><p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Perusahaan dapat menggunakan Data Pribadi untuk tujuan berikut:
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Untuk menyediakan dan memelihara Layanan kami, termasuk untuk memantau
        penggunaan Layanan kami.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Untuk mengelola Akun Anda: untuk mengelola pendaftaran Anda sebagai
        pengguna Layanan. Data Pribadi yang Anda berikan dapat memberi Anda
        akses ke berbagai fungsi Layanan yang tersedia bagi Anda sebagai
        pengguna terdaftar.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Untuk pelaksanaan kontrak: pengembangan, kepatuhan, dan pelaksanaan
        kontrak pembelian untuk produk, barang, atau layanan yang telah Anda
        beli atau kontrak lain dengan Kami melalui Layanan.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Untuk menghubungi Anda: Untuk menghubungi Anda melalui email, panggilan
        telepon, SMS, atau bentuk komunikasi elektronik setara lainnya, seperti
        pemberitahuan push aplikasi seluler mengenai pembaruan atau komunikasi
        informatif yang terkait dengan fungsi, produk, atau layanan yang
        dikontrak, termasuk pembaruan keamanan, jika diperlukan atau wajar untuk
        penerapannya.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Untuk memberi Anda berita, penawaran khusus, dan informasi umum tentang
        barang, layanan, dan acara lain yang kami tawarkan yang serupa dengan
        yang telah Anda beli atau tanyakan kecuali Anda telah memilih untuk
        tidak menerima informasi tersebut.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Untuk mengelola permintaan Anda: Untuk menghadiri dan mengelola
        permintaan Anda kepada Kami.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Untuk transfer bisnis: Kami dapat menggunakan informasi Anda untuk
        mengevaluasi atau melakukan penggabungan, divestasi, restrukturisasi,
        reorganisasi, pembubaran, atau penjualan atau transfer lain atas
        sebagian atau seluruh aset Kami, baik sebagai usaha yang masih berjalan
        atau sebagai bagian dari kebangkrutan, likuidasi, atau proses serupa, di
        mana Data Pribadi yang Kami miliki tentang pengguna Layanan kami
        termasuk di antara aset yang ditransfer.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Untuk tujuan lain: Kami dapat menggunakan informasi Anda untuk tujuan
        lain, seperti analisis data, mengidentifikasi tren penggunaan,
        menentukan efektivitas kampanye promosi kami, dan untuk mengevaluasi dan
        meningkatkan Layanan, produk, layanan, pemasaran, dan pengalaman Anda.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Kami dapat membagikan informasi pribadi Anda dalam situasi berikut:
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Dengan Penyedia Layanan: Kami dapat membagikan informasi pribadi Anda
        dengan Penyedia Layanan untuk memantau dan menganalisis penggunaan
        Layanan kami, untuk menghubungi Anda.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Untuk transfer bisnis: Kami dapat membagikan atau mentransfer informasi
        pribadi Anda sehubungan dengan, atau selama negosiasi, penggabungan,
        penjualan aset Perusahaan, pembiayaan, atau akuisisi semua atau sebagian
        bisnis Kami ke perusahaan lain.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Dengan Afiliasi: Kami dapat membagikan informasi Anda dengan afiliasi
        Kami, dalam hal ini kami akan meminta afiliasi tersebut untuk
        menghormati Kebijakan Privasi ini. Afiliasi termasuk perusahaan induk
        Kami dan anak perusahaan lainnya, mitra usaha patungan, atau perusahaan
        lain yang Kami kendalikan atau yang berada di bawah kendali bersama
        Kami.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Dengan mitra bisnis: Kami dapat membagikan informasi Anda dengan mitra
        bisnis Kami untuk menawarkan Anda produk, layanan, atau promosi
        tertentu.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Dengan pengguna lain: ketika Anda membagikan informasi pribadi atau
        berinteraksi di area publik dengan pengguna lain, informasi tersebut
        dapat dilihat oleh semua pengguna dan dapat didistribusikan secara
        publik di luar.
      </span>
    </p>
    <p style={{ marginLeft: "28.35pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Dengan persetujuan Anda: Kami dapat mengungkapkan informasi pribadi Anda
        untuk tujuan lain dengan persetujuan Anda.
      </span>
    </p></>]
  },
  {
    title:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Penyimpanan Data Pribadi Anda
      </span>
    </p></>],

    content:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Perusahaan akan menyimpan Data Pribadi Anda hanya selama diperlukan
        untuk tujuan yang ditetapkan dalam Kebijakan Privasi ini. Kami akan
        menyimpan dan menggunakan Data Pribadi Anda sejauh yang diperlukan untuk
        mematuhi kewajiban hukum kami (misalnya, jika kami diharuskan menyimpan
        data Anda untuk mematuhi hukum yang berlaku), menyelesaikan
        perselisihan, dan menegakkan perjanjian dan kebijakan hukum kami.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Perusahaan juga akan menyimpan Data Penggunaan untuk tujuan analisis
        internal. Data Penggunaan umumnya disimpan untuk jangka waktu yang lebih
        pendek, kecuali jika data ini digunakan untuk memperkuat keamanan atau
        untuk meningkatkan fungsionalitas Layanan Kami, atau Kami secara hukum
        berkewajiban untuk menyimpan data ini untuk jangka waktu yang lebih
        lama.
      </span>
    </p></>]
  },
  {
    title:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Transfer Data Pribadi Anda
      </span>
    </p></>],

    content:[<><p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Informasi Anda, termasuk Data Pribadi, diproses di kantor operasional
        Perusahaan dan di tempat lain tempat pihak yang terlibat dalam
        pemrosesan berada. Artinya, informasi ini dapat ditransfer ke — dan
        disimpan di — komputer yang berlokasi di luar negara bagian, provinsi,
        negara, atau yurisdiksi pemerintahan Anda yang undang-undang
        perlindungan datanya mungkin berbeda dari yurisdiksi Anda.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Persetujuan Anda terhadap Kebijakan Privasi ini diikuti dengan
        penyerahan informasi tersebut oleh Anda merupakan persetujuan Anda
        terhadap transfer tersebut.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Perusahaan akan mengambil semua langkah yang diperlukan secara wajar
        untuk memastikan bahwa data Anda diperlakukan dengan aman dan sesuai
        dengan Kebijakan Privasi ini dan tidak ada transfer Data Pribadi Anda
        yang akan dilakukan ke organisasi atau negara mana pun kecuali ada
        kontrol yang memadai termasuk keamanan data Anda dan informasi pribadi
        lainnya.
      </span>
    </p></>]
  },
  {
    title:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Hapus Data Pribadi Anda
      </span>
    </p></>],

    content:[<><p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Anda berhak menghapus atau meminta Kami membantu menghapus Data Pribadi
        yang telah Kami kumpulkan tentang Anda.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Layanan Kami dapat memberi Anda kemampuan untuk menghapus informasi
        tertentu tentang Anda dari dalam Layanan.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Anda dapat memperbarui, mengubah, atau menghapus informasi Anda kapan
        saja dengan masuk ke Akun Anda, jika Anda memilikinya, dan mengunjungi
        bagian pengaturan akun yang memungkinkan Anda mengelola informasi
        pribadi Anda. Anda juga dapat menghubungi Kami untuk meminta akses,
        memperbaiki, atau menghapus informasi pribadi apa pun yang telah Anda
        berikan kepada Kami.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Namun, perlu diketahui bahwa Kami mungkin perlu menyimpan informasi
        tertentu ketika kami memiliki kewajiban hukum atau dasar hukum untuk
        melakukannya.
      </span>
    </p></>]
  },
  {
    title:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Pengungkapan Data Pribadi Anda
      </span>
    </p></>],

    content:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Transaksi Bisnis
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Jika Perusahaan terlibat dalam penggabungan, akuisisi, atau penjualan
        aset, Data Pribadi Anda dapat ditransfer. Kami akan memberikan
        pemberitahuan sebelum Data Pribadi Anda ditransfer dan menjadi subjek
        Kebijakan Privasi yang berbeda.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Penegakan hukum
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Dalam keadaan tertentu, Perusahaan mungkin diharuskan untuk
        mengungkapkan Data Pribadi Anda jika diharuskan oleh hukum atau sebagai
        tanggapan atas permintaan yang sah oleh otoritas publik (misalnya
        pengadilan atau lembaga pemerintah).
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Persyaratan hukum lainnya
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Perusahaan dapat mengungkapkan Data Pribadi Anda dengan keyakinan yang
        baik bahwa tindakan tersebut diperlukan untuk:
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Mematuhi kewajiban hukum
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Melindungi dan mempertahankan hak atau properti Perusahaan
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Mencegah atau menyelidiki kemungkinan pelanggaran sehubungan dengan
        Layanan
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Melindungi keselamatan pribadi Pengguna Layanan atau publik
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Melindungi dari tanggung jawab hukum
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Keamanan Data Pribadi Anda
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Keamanan Data Pribadi Anda penting bagi Kami, tetapi ingatlah bahwa
        tidak ada metode transmisi melalui Internet, atau metode penyimpanan
        elektronik yang 100% aman. Meskipun Kami berupaya menggunakan cara yang
        dapat diterima secara komersial untuk melindungi Data Pribadi Anda, Kami
        tidak dapat menjamin keamanannya secara mutlak.
      </span>
    </p></>]
  },
  {
    title:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Informasi Terperinci tentang Pemrosesan Data Pribadi Anda
      </span>
    </p></>],

    content:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Penyedia Layanan yang Kami gunakan mungkin memiliki akses ke Data
        Pribadi Anda. Vendor pihak ketiga ini mengumpulkan, menyimpan,
        menggunakan, memproses, dan mentransfer informasi tentang aktivitas Anda
        di Layanan Kami sesuai dengan Kebijakan Privasi mereka.
      </span>
    </p></>]
  },
  {
    title:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Penggunaan, Performa, dan Lain-lain
      </span>
    </p></>],

    content:[<><p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Kami dapat menggunakan Penyedia Layanan pihak ketiga untuk memelihara
        dan meningkatkan Layanan kami.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginLeft: "28.35pt",
        textIndent: "-14.15pt",
        AwImport: "list-item",
        AwListLevelNumber: 0,
        AwListNumberFormat: '"•"',
        AwListNumberStyles: '"bullet"',
        AwListPaddingSml: "8.17pt"
      }}
    >
      <span style={{ AwImport: "ignore" }}>
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          <span>•</span>
        </span>
        <span
          style={{
            width: "8.17pt",
            font: '7pt "Times New Roman"',
            display: "inline-block",
            AwImport: "spaces"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
      </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Google Places
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <br />
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Google Places adalah layanan yang memberikan informasi tentang tempat
        menggunakan permintaan HTTP. Layanan ini dioperasikan oleh Google
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Layanan Google Places dapat mengumpulkan informasi dari Anda dan dari
        Perangkat Anda untuk tujuan keamanan.
      </span>
      <span style={{ fontFamily: '"MS Gothic"', color: "#353535" }}> </span>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Informasi yang dikumpulkan oleh Google Places disimpan sesuai dengan
        Kebijakan Privasi Google:{" "}
      </span>
      <a
        href="https://www.google.com/intl/en/policies/privacy/"
        style={{ textDecoration: "none" }}
      >
        <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
          https://www.google.com/intl/en/policies/privacy/
        </span>
      </a>
    </p></>]
  },
  {
    title:[<><p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Privasi Anak
      </span>
    </p></>],

    content:[<><p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Layanan Kami tidak ditujukan untuk siapa pun yang berusia di bawah 13
        tahun. Kami tidak dengan sengaja mengumpulkan informasi identitas
        pribadi dari siapa pun yang berusia di bawah 13 tahun. Jika Anda adalah
        orang tua atau wali dan Anda mengetahui bahwa anak Anda telah memberikan
        Data Pribadi kepada Kami, silakan hubungi Kami. Jika Kami mengetahui
        bahwa Kami telah mengumpulkan Data Pribadi dari siapa pun yang berusia
        di bawah 13 tahun tanpa verifikasi persetujuan orang tua, Kami mengambil
        langkah-langkah untuk menghapus informasi tersebut dari server Kami.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Jika Kami perlu mengandalkan persetujuan sebagai dasar hukum untuk
        memproses informasi Anda dan negara Anda mengharuskan persetujuan dari
        orang tua, Kami mungkin memerlukan persetujuan orang tua Anda sebelum
        Kami mengumpulkan dan menggunakan informasi tersebut.
      </span>
    </p></>]
  },
  {
    title:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Tautan ke Situs Web Lain
      </span>
    </p></>],

    content:[<><p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Layanan Kami mungkin berisi tautan ke situs web lain yang tidak
        dioperasikan oleh Kami. Jika Anda mengeklik tautan pihak ketiga, Anda
        akan diarahkan ke situs pihak ketiga tersebut. Kami sangat menyarankan
        Anda untuk meninjau Kebijakan Privasi setiap situs yang Anda kunjungi.
      </span>
    </p><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Kami tidak memiliki kendali atas dan tidak bertanggung jawab atas
        konten, kebijakan privasi, atau praktik situs atau layanan pihak ketiga
        mana pun.
      </span>
    </p></>]
  },
  {
    title:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Perubahan pada Kebijakan Privasi ini
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Kami dapat memperbarui Kebijakan Privasi kami dari waktu ke waktu. Kami
        akan memberi tahu Anda tentang perubahan apa pun dengan mengeposkan
        Kebijakan Privasi baru di halaman ini.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Kami akan memberi tahu Anda melalui email dan/atau pemberitahuan yang
        mencolok di Layanan Kami, sebelum perubahan tersebut berlaku dan
        memperbarui tanggal "Terakhir diperbarui" di bagian atas Kebijakan
        Privasi ini.
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        Anda disarankan untuk meninjau Kebijakan Privasi ini secara berkala
        untuk mengetahui perubahan apa pun. Perubahan pada Kebijakan Privasi ini
        berlaku saat diposkan di halaman ini.
      </span>
    </p></>],

    title:[<><p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p style={{ fontSize: "18pt" }}>
      <span
        style={{
          fontFamily: "Poppins, sans-serif Bold",
          fontWeight: "bold",
          color: "#353535"
        }}
      >
        Contact Us
      </span>
    </p></>],

    content:[<><p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        If you have any questions about this Privacy Policy, You can contact us:
      </span>
    </p>
    <p>
      <span
        style={{
          fontFamily: "Poppins, sans-serif",
          color: "#353535",
          AwImport: "ignore"
        }}
      >
        &nbsp;
      </span>
    </p>
    <p>
      <span style={{ fontFamily: "Poppins, sans-serif", color: "#353535" }}>
        By email: pikkat.store@gmail.com
      </span>
    </p>
    <p>
      <span style={{ AwImport: "ignore" }}>&nbsp;</span>
    </p></>]
  }
];