import BeritaCards from "../components/Berita/berita-terbaru";
import BeritaIntro from "../components/Berita/berita-intro";
import BeritaPopuler from "../components/Berita/berita-populer";
import axios from "axios";
import base_url from "../constants/global"; 
import React, {useState, useEffect} from "react";

export default function Berita() {
  const [articles, setArciles] = useState([]);
  useEffect(() => {
    axios
      .post(`${base_url}/articel_terbaru`)
      .then((response) => {
        setArciles(response.data.articel_terbaru);
      })
      .catch((error) => {
        console.error("Error fetching the posts:", error);
      });
  }, []);

  const [berita, setBerita] = useState([]);
  useEffect(() => {
    axios
      .post(`${base_url}/berita_terbaru`)
      .then((response) => {
        setBerita(response.data.blog_terbaru);
      })
      .catch((error) => {
        console.error("Error fetching the posts:", error);
      });
  }, []);

  const [global, setGlobal] = useState([]);
  useEffect(() => {
    axios
      .post(`${base_url}/blog_berita`)
      .then((response) => {
        setGlobal(response.data.blog_berita);
      })
      .catch((error) => {
        console.error("Error fetching the posts:", error);
      });
  }, []);
  
  return (
    <>
    {articles.length > 0 ? (

    
    <div className="bg-gradient-to-br from-pikkat-green/10 via-white to-pikkat-green/10 flex flex-col justify-center">
      <div className="container mx-auto py-12">
        <BeritaIntro global={global} />
        <BeritaCards articles={berita} />
        <BeritaPopuler articles={articles} />
      </div>
    </div>
    ) : (<div className="flex flex-col items-center justify-center min-w-full min-h-screen">
      <p>loading...</p>
      <img src={require("../assets/icons/loading.gif")} style={{height:"90px"}}/>
    </div>)}
    </>
  );
}
