import React, { useState, useEffect } from "react";
import base_url from "../../constants/global";
import axios from "axios";

export default function Achievements() {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .post(`${base_url}/home_page`)
      .then((response) => {
        setData(response.data.achievement);
      })
      .catch((error) => {
        console.error("Error fetching the posts:", error);
      });
  }, []);

  return (
    <div className="bg-gradient-to-br from-pikkat-green/10 to-pikkat-green/5 via-white ">
        <div className="flex flex-col px-6 lg:px-24 pt-12 lg:pt-10 lg:pb-24">
          <div className=" flex flex-col text-center gap-5">
            <h1 className="text-3xl font-semibold">
              Pencapaian <span className="text-pikkat-green">Kami</span>
            </h1>
            <p className="light-text text-lg self-center md:max-w-[60%]">
            Pikkat berkomitmen untuk mendekatkan layanan  kepada masyarakat dengan jangkauan lebih luas.
            </p>
            {data.length > 0 ? (
              <div className="flex flex-col md:flex-row justify-center font-bold text-pikkat-red py-6 gap-5 md:gap-0">
                <div className="flex-1">
                  <h1 className="text-5xl">{data[0].value_setting}+</h1>
                  <p className="light-text text-lg text-semibold pt-2">
                  {data[0].name_setting}
                  </p>
                </div>
                <div className="flex-1">
                  <h1 className="text-5xl">{data[1].value_setting}+</h1>
                  <p className="light-text text-lg text-semibold pt-2">{data[1].name_setting}</p>
                </div>
                <div className="flex-1">
                  <h1 className="text-5xl">{data[2].value_setting}+</h1>
                  <p className="light-text text-lg text-semibold pt-2">
                  {data[2].name_setting}
                  </p>
                </div>
              </div>
            ) : (
              <div>no data found</div>
            )}
          </div>
          <div className="w-full flex flex-row justify-center items-center h-[10px] py-8">
            <div className="min-w-[310px] h-[10px] border-b border-pikkat-green/70 flex flex-row"></div>
          </div>
          <div className="flex flex-col text-center gap-5 pt-8">
            <h1 className="text-3xl font-semibold">
              Partner{" "}
              <span className="text-pikkat-green">Pikkat</span>
            </h1>
            <p className="light-text text-lg self-center md:max-w-[60%]">
            Kami bekerjasama dengan berbagai partner yang berkompeten untuk memenuhi kebutuhan anda.
            </p>
            <div className="flex flex-row justify-center gap-8 items-center flex-wrap scale-75 lg:scale-100">
              <img
                src={require("../../assets/feb-ui.png")}
                alt="google"
                className="lg:max-h-[150px] max-w-[200px] object-cover"
              />
              <img
                src={require("../../assets/logo-fs.png")}
                alt="netflix"
                className="lg:max-h-[150px] max-w-[200px] object-cover"
              />
              <img
                src={require("../../assets/logo-ubd.png")}
                alt="airbnb"
                className="lg:max-h-[150px] max-w-[200px] object-cover"
              />
              <img
                src={require("../../assets/tangerang-logo.png")}
                alt="amazon"
                className="lg:max-h-[150px] max-w-[200px] object-cover"
              />
              <img
                src={require("../../assets/logo-pgri.png")}
                alt="facebook"
                className="lg:max-h-[150px] max-h-[60px]"
              />
              <img
                src={require("../../assets/kraft-heinz.png")}
                alt="amazon"
                className="lg:max-h-[150px] max-h-[60px]"
              />
            </div>
          </div>
        </div>
    </div>
  );
}
