import React from "react";
import  { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../../constants/global"


const VisiMisi = () => {
  const titles = ["Visi", "Misi"];
  const descriptions = [
    "Menjadi Pemimpin Penyedia Manajemen Layanan Retail Untuk UMKM Di Indonesia",
    "Memperkuat Seluruh Partner Kami – Para Pelaku Retail Umkm Dan Seluruh Konsumen Mereka",
  ];

  const [visiMisi, setVisiMisi] = useState("");
  
  useEffect(() => {
    axios.post(`${base_url}/home_page`)
      .then(response => {
        // setData(response.data.video);
        setVisiMisi(response.data.video[0]['value_setting']);
      })
      .catch(error => {
        console.error('Error fetching the posts:', error);
      });
  }, []);

const VisiMisiCard = ({ title, descriptions, index }) => {
  return (
    <div className="flex flex-col bg-white p-4 border-2 border-pikkat-gray-2/30 rounded-xl hover:border-pikkat-green hover:bg-pikkat-green/10 gap-3 duration-300">
        <img src={require('../../assets/icons/visi-misi-icon.png')} alt='visi' className='h-[40px] w-[40px]'/>
      <p className='font-semibold text-lg'>{title}</p>
      <p className='text-pikkat-gray'>{descriptions[index]}</p>
    </div>
  );
};

  return (
    <div className="bg-gradient-to-tr from-pikkat-green/10 to-white from-[10%] to-[40%] min-h-[500px] py-12">
      <div className="container mx-auto flex flex-col gap-6">
        <div className="flex flex-col gap-4 items-center justify-center lg:px-40 text-center">
          <h1 className="font-semibold text-3xl pb-6 text-pikkat-green">
            Visi & Misi
            <span className="text-pikkat-black"> Kami</span>
          </h1>
          <p className="text-pikkat-gray-2/80 font-medium px-2 lg:px-24">
            PT Altadaya Angsana (“Pikkat ” atau “Perusahaan”) didirikan pada
            tahun 2019 oleh Herman BG. Pikkat adalah platform pelayanan jasa
            pengembangan warung atau toko ritel UMKM
          </p>
        </div>

        <div className="lg:grid grid-flow-col grid-cols-12 pt-6">
          <div className="col-span-7 items-center flex justify-center relative">
            <img
              src={require("../../assets/visi-misi-background.png")}
              alt="visi background"
              className="max-h-[450px] w-auto"
            />
            <div className="absolute min-h-24 min-w-24 w-full lg:px-24 px-4 flex flex-row items-center justify-center">
                <iframe
                  width="100%"
                  src={visiMisi}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="rounded-2xl h-[250px] md:h-[343px]"
                ></iframe>
            </div>
          </div>
          <div className="col-span-5 flex flex-col gap-8 items-center justify-center px-4 lg:px-0 lg:pr-24">
            {titles.map((title, index) => (
              <VisiMisiCard
              key={index}
                title={title}
                descriptions={descriptions}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisiMisi;
