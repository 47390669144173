import Keuntungan from "../components/MitraPemasok/keuntungan-pemasok.js"
import Layanan from "../components/MitraPemasok/layanan-pemasok.js";
import IntroPemasok from "../components/MitraPemasok/intro-pemasok.js";
import FaqMitra from "../components/MitraPemasok/faq-pemasok.js";
import IntouchMitra from "../components/MitraPemasok/getin-touch-pemasok.js";

const OurService = () => {
  return (
    <div className="w-full">
      <IntroPemasok/>
      <Layanan/>
      <Keuntungan/>    
      <FaqMitra/>
      <IntouchMitra/>
    </div>
  );
};

export default OurService;
