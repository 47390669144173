import BeritaTerbaru from "../components/Berita/berita-terbaru";
import Article from "../components/DetailBerita/artikel";
import SideCards from "../components/DetailBerita/side-cards";
import axios from "axios";
import base_url from "../constants/global";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export default function DetailBerita() {
  const {id} = useParams()
  const [articles, setArciles] = useState([]);
  useEffect(() => {
    axios
      .post(`${base_url}/blog_berita`, {id})
      .then((response) => {
        setArciles(response.data.blog_berita);
      })
      .catch((error) => {
        console.error("Error fetching the posts:", error);
      });
  }, []);
  return (
    <>
      {articles.length > 0 ? (
        <div className="flex flex-col min-h-screen">
          <div className="bg-gradient-to-br from-pikkat-green/10 via-white to-pikkat-green/10 from-20% py-12">
            <div className="lg:grid grid-flow-col grid-cols-12 container mx-auto gap-6">
              <div className="col-span-8 bg-white/70 p-4 rounded-xl">
                <Article />
              </div>

              <div className="col-span-4">
                <SideCards articles={articles} />
              </div>
            </div>
          </div>

          <div className="container mx-auto py-12">
            <BeritaTerbaru articles={articles} />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center min-w-full min-h-screen">
          <p>loading...</p>
          <img src={require("../assets/icons/loading.gif")} style={{height:"90px"}} alt="loading-gif"/>
        </div>
      )}
    </>
  );
}
