import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import base_url from "../../constants/global";


const BeritaTerbaruCard = ({article, index}) => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };
  return (
    <div
      className="min-h-[320px] col-span-1 flex flex-col bg-white px-3 py-4 rounded-xl gap-[4px] shadow-xl shadow-black/12  hover:cursor-pointer"
      onClick={() => navigateTo(`/detail-berita/${article.id}`)}
    >
      <img
        src={article.images}
        alt="berita card"
        className="h-[150px] object-cover"
      />
      <div className="flex flex-row gap-5 text-[9px] text-pikkat-gray-2 items-center">
        <p>{article.admin}</p>
        <img
          src={require("../../assets/icons/dot.png")}
          alt="just dot"
          className="max-h-[6px] w-auto self-center"
        />
        <p>{article.updated.slice(0,10)}</p>
        <button className=" text-orange-400 font-semibold border-[1.5px] border-orange-400 bg-orange-200/30 p-1 px-2 rounded-lg">
          {article.categori}
        </button>
      </div>
      <p className="font-semibold text-sm">{article.title.slice(0,120)}</p>
      <p className="text-[11px]">
      {/* <div dangerouslySetInnerHTML={ { __html: article.content.slice(0, 200) } }></div> */}
      </p>
    </div>
  );
};

const BeritaTerbaru = ({articles}) => {
  return (
    <div className="flex flex-col gap-3">
      <p className=" text-blue-400 font-semibold text- flex flex-row items-center gap-2">
        <span>
          <img src={require("../../assets/icons/red-dot.png")} alt="red dot" />
        </span>
        Berita Terbaru
      </p>
      <div className="lg:grid grid-flow-col grid-cols-5 gap-4 justify-center items-center border-t-[1.5px] border-pikkat-gray-2/20 pt-6">
        {articles.slice(0, 5).map((article, index) => (
          <BeritaTerbaruCard key={index} article={article} index={index} />
        ))}
      </div>
    </div>
    
  );
};

export default BeritaTerbaru;
