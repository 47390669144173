import { useNavigate } from "react-router-dom";


const BeritaPopulerCard = ({ article, index }) => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };
  return (
    <div className="flex flex-col gap-3 lg:grid grid-flow-col grid-cols-11 p-2 items-stretch min-h-[100px] min-w-[250px] lg:min-w-[20px] hover:cursor-pointer bg-white rounded-lg"  onClick={() => navigateTo(`/detail-berita/${article.id}`)}>
      <div className="col-span-4">
        <img
          src={article.images}
          alt="berita card"
          className="min-w-full h-[120px] object-cover rounded-xl"
        />
      </div>
      <div
        className="col-span-7 flex flex-col gap-[1px] px-4 text-justify"
      >
        <div className="flex flex-row gap-2 lg:text-xs text-[10px] text-pikkat-gray-2/80 items-center ">
          <p>{article.admin}</p>
          <img
            src={require("../../assets/icons/dot.png")}
            alt="just dot"
            className="max-h-[6px] w-auto self-center"
          />
          <p>{article.updated.slice(0,10)}</p>
          <button className=" text-orange-400 font-semibold border-[1.5px] border-orange-400 bg-orange-200/30 p-1 px-2 rounded-lg">
            {article.categori}
          </button>
        </div>
        <h3 className="text-[12px] font-semibold">
          {article.title}
        </h3>
        <p className="text-[10px] text-pikkat-gray-2">
        <div dangerouslySetInnerHTML={ { __html: article.content.slice(0, 150) } }></div>
        </p>
      </div>
    </div>
  );
};

const BeritaPopuler = ({articles}) => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };
  return (
    <div className="flex flex-col pt-20 pb-8 gap-4">
      <div>
        <p className=" text-blue-400 font-semibold text- flex flex-row items-center gap-2">
          <span>
            <img
              src={require("../../assets/icons/red-dot.png")}
              alt="red dot"
            />
          </span>
          Artikel Terbaru
        </p>
      </div>
      <div className="lg:grid grid-flow-col grid-cols-12 gap-6 pt-4 border-t-2">
        <div
          className="col-span-6 flex flex-col gap-3 relative group hover:cursor-pointer"
          onClick={() => navigateTo(`/detail-berita/${articles[0].id}`)}
        >
          <img
            src={articles[0].images}
            alt="intro berita"
            className="min-h-full object-cover rounded-2xl"
          />
          <div className="lg:hidden absolute bottom-0 group-hover:block group-hover:backdrop-blur-sm bg-black/10 p-8 text-white animate-fadeIn gap-5">
            <div className="flex flex-row gap-2 text-[10px] items-center font-semibold">
              <p>{articles[0].admin}</p>
              <img
                src={require("../../assets/icons/dot.png")}
                alt="just dot"
                className="max-h-[6px] w-auto self-center"
              />
              <p>{articles[0].updated}</p>
              <button className=" text-white font-semibold border-[1.5px] border-orange-400 bg-orange-200/30 p-1 px-2 rounded-lg">
                {articles[0].categori}
              </button>
            </div>
            <h1 className="font-semibold">{articles[0].title}</h1>
            <p className="text-[11px] hidden lg:block">
            <div dangerouslySetInnerHTML={ { __html: articles[0].content.slice(0, 150) } }></div>
            </p>
          </div>
        </div>

        <div className="col-span-7 flex lg:flex-col gap-3 overflow-x-scroll">
          {articles.slice(0,3).map((article, index) => (
            <BeritaPopulerCard key={index} article={article} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BeritaPopuler;
