import ProfileImg1 from '../ratting1.jpg'
import ProfileImg2 from '../ratting2.jpg'
import ProfileImg3 from '../ratting3.jpg'

const reviewsDummy = [
    {
        id: 1,
        src: ProfileImg1,
        desc: 'Dirut Reliance Finance, Mantan Dirut Fairbanc, Mantan Direktur Lembaga Pengelolaan Dana  Bergulir Kemenkop UKM untuk Koperasi & UMKM , dan mantan Direktur Risk & Keuangan CIMB Niaga Auto Finance. Lebih dari 20 tahun di Industri Jasa Keuangan baik di sektor swasta maupun organisasi pemerintah.',
        position: 'Founder/Chairman',
        name: 'Herman BG',
        bgColor: '#cfebf1'
    },
    {
        id: 2,
        src: ProfileImg2,
        desc: 'Dirut Reliance Finance, Mantan Dirut Fairbanc, Mantan Direktur Lembaga Pengelolaan Dana  Bergulir Kemenkop UKM untuk Koperasi & UMKM , dan mantan Direktur Risk & Keuangan CIMB Niaga Auto Finance. Lebih dari 20 tahun di Industri Jasa Keuangan baik di sektor swasta maupun organisasi pemerintah.',
        position: 'Founder/Director',
        name: 'Iman Pribadi',
        bgColor: '#E3D6FF'
    },
    {
        id: 3,
        src: ProfileImg3,
        desc: 'Dirut Reliance Finance, Mantan Dirut Fairbanc, Mantan Direktur Lembaga Pengelolaan Dana  Bergulir Kemenkop UKM untuk Koperasi & UMKM , dan mantan Direktur Risk & Keuangan CIMB Niaga Auto Finance. Lebih dari 20 tahun di Industri Jasa Keuangan baik di sektor swasta maupun organisasi pemerintah.',
        position: '-',
        name: 'William Mandeta',
        bgColor: '#FFE9BD'
    }
    
]

export default reviewsDummy