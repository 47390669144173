
const MenjadiMitraToko = () => {
  return (
    <div className="bg-gradient-to-br from-[20%] via-[30%] to-[90%] from-pikkat-green/5 via-white to-pikkat-green/10 pb-16">
      <div className="container mx-auto py-12">
        <div className="flex flex-col items-center justify-center space-y-3">
          <h1 className="text-[24px] md:text-[32px] font-semibold">
            Menjadi Mitra <span className="text-pikkat-green">toko</span>
          </h1>
          <p className="lg:w-[80%] px-2 text-justify lg:text-center text-pikkat-gray-2 lg:px-32 text-md pt-4">
          Pikkat menyediakan solusi yang menyeluruh (end-to-end) bagi pemilik warung/toko UMKM meningkatkan pendapatan, margin keuntungan yang lebih baik, dengan proses yang lebih efisien dan menciptakan nilai tambah.
          </p>
        </div>
        <div className="flex flex-col-reverse lg:grid grid-cols-12 gap-16 lg:gap-6 mt-[60px]">
          <div className="col-span-6 grid items-center px-4 lg:px-0 lg:pr-12 lg:pl-4 gap-3">
            <div className="flex flex-row gap-3 hover:text-[16px] hover:p-5 bg-white rounded-lg text-md p-3 border-[1.5px] text-pikkat-gray/90 hover:text-pikkat-black hover:font-medium border-transparent shadow-md hover:border-pikkat-green hover:bg-pikkat-green/10 duration-200">
              <p className="text-3xl">.</p>
              <p className="">
              Warung dapat menjual lebih banyak variasi barang atau produk, dibandingkan dengan persediaan barang warung pada saat ini, tanpa harus menambah persediaan.
              </p>
            </div>
            <div className="flex flex-row gap-3 hover:text-[16px] hover:p-5 bg-white rounded-lg text-md p-3 border-[1.5px] text-pikkat-gray/90 hover:text-pikkat-black hover:font-medium border-transparent shadow-md hover:border-pikkat-green hover:bg-pikkat-green/10 duration-200">
              <p className="text-3xl">.</p>
              <p className="text-md">
              Warung sekarang dapat menjangkau lebih banyak pelanggan, karena dengan Pikkat warung bisa berjualan secara offline maupun online.
              </p>
            </div>
            <div className="flex flex-row gap-3 hover:text-[16px] hover:p-5 bg-white rounded-lg text-md p-3 border-[1.5px] text-pikkat-gray/90 hover:text-pikkat-black hover:font-medium border-transparent shadow-md hover:border-pikkat-green hover:bg-pikkat-green/10 duration-200">
              <p className="text-3xl">.</p>
              <p className="">
              Jalur distribusi produk yang dimiliki Pikkat sangat efisien, sehingga warung dapat memperoleh produk dengan harga yang sangat kompetitif dan dapat berjualan dengan margin yang lebih baik.
              </p>
            </div>
            <div className="flex flex-row gap-3 hover:text-[16px] hover:p-5 bg-white rounded-lg text-md p-3 border-[1.5px] text-pikkat-gray/90 hover:text-pikkat-black hover:font-medium border-transparent shadow-md hover:border-pikkat-green hover:bg-pikkat-green/10 duration-200">
              <p className="text-3xl">.</p>
              <p className="">
              Warung bisa memiliki catatan keuangan yang lebih baik yang dapat digunakan untuk mengakses layanan permodalan yang disediakan oleh mitra Pikkat</p>
            </div>
            <div className="flex flex-row gap-3 hover:text-[16px] hover:p-5 bg-white rounded-lg text-md p-3 border-[1.5px] text-pikkat-gray/90 hover:text-pikkat-black hover:font-medium border-transparent shadow-md hover:border-pikkat-green hover:bg-pikkat-green/10 ease-out duration-200">
              <p className="text-3xl">.</p>
              <p className="">
              Solusi yang dimiliki Pikkat juga akan memberikan kemudahan bagi siapa saja yang ingin membuka warung/toko baru.
              </p>
            </div>
          </div>
          <div className="col-span-6 object-contain relative flex items-center">
            <img
              className="scale-75 lg:scale-100 hidden lg:block absolute bottom-0 -left-12 z-20"
              alt="background"
              src={require("../../assets/icons/rectangle.png")}
            />
            <img
              className="scale-75 scale lg:scale-100 hidden lg:block absolute top-0 right-0 z-20"
              alt="background"
              src={require("../../assets/icons/rectangle.png")}
            />
            <img
              src={require("../../assets/menjadi-mitra-toko.png")}
              alt="tujuan"
              className="scale-[90%] top-16 z-30"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenjadiMitraToko;
