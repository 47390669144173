import React from "react";
import { ReactSVG } from "react-svg";
import Artwork from "../../assets/dummy/artwork.png";
import PrincipleIcon from "../../assets/icons/svg/lightbulb-icon.svg";

const PrinsipKami = () => {
  return (
    <div className="bg-gradient-to-br from-pikkat-green/10 to-pikkat-green/5 via-white">
      <div className="flex flex-col px-2 lg:px-24 pt-12 lg:pt-24 lg:pb-24">
        <div className="flex flex-col justify-center items-center lg:w-[59%] self-center">
          <h1 className="font-semibold text-3xl mb-10">
            <span className="primary-color">Prinsip</span> Kami
          </h1>
          <p className="text-center text-lg text-slate-500">
          Nilai perusahaan bagi kami adalah landasan motivasi karyawan sekaligus menjadi ciri khas perusahaan, di mana prinsip yang kami pegang adalah semua hal yang kami lakukan memiliki nilai dampak sosial dan menjadi solusi end-to-end bagi pelaku ritel UMKM.
          </p>
        </div>
        <div className="lg:grid grid-cols-2 lg:pt-24  lg:gap-10">
          <div className="flex justify-center items-center">
            <img src={Artwork} className="lg:absolute z-10" alt="artwork" />
          </div>
          <div className="flex flex-col gap-4 lg:grid grid-cols-2 lg:gap-10 mt-36 lg:-mt-12">
            <div className="flex justify-center items-center">
              <div className="border-2 flex flex-wrap flex-col border-white-200 hover:border-pikkat-green py-12 px-8 rounded-xl w-[100%] h-[100%] shadow-md">
                <ReactSVG src={PrincipleIcon} className="mb-10" />
                <p className="mb-4 font-semibold text-lg">Convenience</p>
                <p className=" text-slate-500 lg:text-justify hyphens-auto text-sm">
                Memberikan kenyamanan bagi Sobat Pikkat dalam berbelanja karena dapat membeli barang dari warung terdekat dengan lokasi pilihan Sobat Pikkat, dengan pilihan channel belanja yang beragam
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="border-2 flex flex-wrap flex-col border-white-200 hover:border-pikkat-green  py-12 px-8 flex rounded-xl w-[100%] h-[100%] shadow-md">
                <ReactSVG src={PrincipleIcon} className="mb-10" />
                <p className="mb-4 font-semibold text-lg">Affordable</p>
                <p className=" text-slate-500 lg:text-justify hyphens-auto text-sm">
                Memberikan solusi berbelanja yang terjangkau, baik dari sisi harga maupun sisi teknologi. Pun berlaku untuk Mitra Pikkat dengan penggunaan POS dari Pikkat secara gratis.
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="border-2 flex flex-wrap flex-col border-white-200 hover:border-pikkat-green  py-12 px-8 flex rounded-xl w-[100%] h-[100%] shadow-md">
                <ReactSVG src={PrincipleIcon} className="mb-10" />
                <p className="mb-4 font-semibold text-lg">
                  Simple, Smart, Safe
                </p>
                <p className=" text-slate-500 lg:text-justify hyphens-auto text-sm">
                Tidak perlu install aplikasi khusus untuk mulai berbelanja.PIlihan produk yang dikategorikan dengan benar untuk kemudahan berbelanja.Belanja aman dari rumah.
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="border-2 flex flex-wrap flex-col border-white-200 hover:border-pikkat-green  py-12 px-8 flex rounded-xl w-[100%] h-[100%] shadow-md">
                <ReactSVG src={PrincipleIcon} className="mb-10" />
                <p className="mb-4 font-semibold text-lg">Healthy</p>
                <p className=" text-slate-500 lg:text-justify hyphens-auto text-sm">
                Tidak hanya menyediakan kebutuhan sehari-hari, tapi Pikkat berkomitmen juga menyediakan produk fresh yang menyehatkan. Ini tersedia di warung dekat Anda!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrinsipKami;
