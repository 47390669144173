import { cn } from "../../lib/utils";

const NavLinkButton = ({ label, className, isSelected, onClick, icon }) => {
  return (
    <button onClick={onClick} className={cn(isSelected ? "bg-[#F8D8D9] text-pikkat-red font-bold flex flex-row text-nowrap" : "text-pikkat-gray-1/80", className, "py-2 px-4 rounded-md flex flex-row text-nowrap w-full lg:w-auto")}>
      {label}
      <span>
      {icon && <img src={icon} alt="icon" className={isSelected? " rotate-180 duration-500" :"" } />}
      </span>
    </button>
  );
};

export default NavLinkButton;
