import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import {RiEyeLine} from "react-icons/ri";
import {RiEyeCloseLine}  from "react-icons/ri";

const DaftarPemasok = () => {
    const navigate = useNavigate();
    const [form, setForm] = React.useState({
      badanusaha: "",
      namapic: "",
      alamat: "",
      phone: "",
      email: "",
    });
  
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");
    const [type, setType] = useState('password');
    const [type2, setType2] = useState('password');
    const [icon1, setIcon] = useState(RiEyeCloseLine);
    const [icon2, setIcon2] = useState(RiEyeCloseLine);
  
    const handleToggle1 = () => {
        if (type==='password'){
        setIcon(RiEyeLine);
        setType('text')
        } else {
        setIcon(RiEyeCloseLine)
        setType('password')
        }
    };
    const handleToggle2 = () => {
        if (type2==='password'){
        setIcon2(RiEyeLine);
        setType2('text')
        } else {
        setIcon2(RiEyeCloseLine)
        setType2('password')
        }
    };
  
    const handleChangeInput = (event) => {
      const { name, value } = event.target;
      setForm((state) => ({ ...state, [name]: value }));
      
      
    };
    const [isProcessing, setIsProcessing] = useState(false);
  
    const handleLogin = async (e) => {
      e.preventDefault();
      if (form.badanusaha === '') {
        Swal.fire({
          title: "Nama Badan Usaha Tidak Boleh Kosong",
          icon: "error",
        });
      } else {
        if (form.namapic === '') {
          Swal.fire({
            title: "Nama PIC Tidak Boleh Kosong",
            icon: "error",
          });
        } else {
          if (form.alamat === '') {
            Swal.fire({
              title: "alamat Usaha Tidak Boleh Kosong",
              icon: "error",
            });
          } else {
            if (form.phone === '') {
              Swal.fire({
                title: "No. Telp Tidak Boleh Kosong",
                icon: "error",
              });
            } else {
              if(isNaN(form.phone)){
                Swal.fire({
                  title: "No. Telp Tidak Valid",
                  icon: "error",
                });
              } else {
                const lengnumb = form.phone.length;
                if (lengnumb < 10){
                  Swal.fire({
                    title: "No. Telp Kurang dari 10 digits",
                    icon: "error",
                  });
                } else {
                  if (form.email === ''){
                    Swal.fire({
                      title: "Email Tidak Boleh Kosong",
                      icon: "error",
                    });
                  } else {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    const setValidEmail = emailRegex.test(form.email);
                    if (setValidEmail === false){
                      Swal.fire({
                        title: "Email Tidak Valid",
                        icon: "error",
                      });
                    } else {
                      setIsProcessing(true);
                      try {
                        const response = await axios.post("https://landing.pikkat.com/add_mitra_pemasok", {
                          badanusaha: form.badanusaha,
                          namapic: form.namapic,
                          alamat: form.alamat,
                          phone: form.phone,
                          email: form.email,
                          password: password,
                          confirm: confirm,
                        });
                        if(response.data.status==='success'){
                          Swal.fire({
                            title: "Pendaftaran Berhasil",
                            icon: "success",
                            confirmButtonColor: "#07214E",
                          }).then(navigate("/daftar-sekarang"));
                          // Handle successful login here
                        } else {
                          Swal.fire({
                            title: response.data.message,
                            icon: "error",
                            confirmButtonColor: "#07214E",
                          });
                        }
                                
                      } catch (error) {
                        
                        Swal.fire({
                          title: "Email or Password incorrect!",
                          icon: "error",
                        });
                      } finally {
                        setIsProcessing(false);
                      }
                    }
                  }                 
                }
              }
            }
          }
        }
      }
            
    };
 
  return (
    <div className="bg-gradient-to-br from-[20%] via-[30%] to-[90%] from-pikkat-green/5 via-white to-pikkat-green/10 pb-16 min-w-screen min-h-screen items-center flex justify-center">
      
      <div className="flex flex-col-reverse lg:grid grid-cols-12 gap-16 lg:gap-6 mt-[60px]">
            <div className="col-span-12 grid items-center px-4 lg:px-0 lg:pr-12 lg:pl-4 gap-3 py-12">              
                <div className="flex flex-col gap-4">
                    <div className="bg-white p-4 lg:p-2 flex flex-col lg:flex-row gap-8 rounded-3xl border-2 border-transparent hover:border-pikkat-green/80">
                    
                    <div className="flex flex-col gap-2 lg:px-12 py-12">
                      <div className="items-center flex flex-col justify-center"> 
                        <img src={require("../assets/icons/pikkat-logo2.png")} alt="pikkat-logo"
                        className="absolute -translate-y-16"
                      /></div>
                      <div className="text-center mb-8">
                        <h2 className="font-semibold">
                          Selamat Bergabung di <span className="text-pikkat-green">Mitra Pemasok</span>
                        </h2>
                        <p className="text-center text-pikkat-gray-2">
                          Jadilah Bagian dari Kesuksesan para Mitra Pikkat
                        </p>
                      </div>                      
                      <label htmlFor="badan-usaha" className="self-start">Nama Badan Usaha</label>
                      <div className="border-pikkat-gray-2 rounded-lg border-[1.5px] px-3 w-full flex flex-row items-center py-2 ">
                        <input
                        id="badan-usaha"
                          type="text"
                          value={form.badanusaha}
                          name="badanusaha"
                          onChange={handleChangeInput}
                          placeholder="CV.Agung Berjaya"
                          className=" focus:ring-0 outline-none w-full"
                        />
                        <img src={require("../assets/icons/icon-toko.png")} alt="icon-toko" />
                      </div>
                      <label htmlFor="nama-pic" className="self-start">Nama PIC</label>
                      <div className="border-pikkat-gray-2 rounded-lg border-[1.5px] px-3 w-full flex flex-row items-center py-2 ">
                        <input
                        id="nama-pic"
                          type="text"
                          value={form.namapic}
                          name="namapic"
                          onChange={handleChangeInput}
                          placeholder="Agung Sanjaya"
                          className=" focus:ring-0 outline-none w-full"
                        />
                        <img src={require("../assets/icons/icon-user.png")} alt="icon-user" />
                      </div>
                      <label htmlFor="alamat-usaha" className="self-start">Alamat Usaha</label>
                      <div className="border-pikkat-gray-2 rounded-lg border-[1.5px] px-3 w-full flex flex-row items-center py-2 ">
                        <input
                        id="alamat-usaha"
                          type="text"
                          value={form.alamat}
                          name="alamat"
                          onChange={handleChangeInput}
                          placeholder="Jl.Kemakmuran No.12 Tangerang"
                          className=" focus:ring-0 outline-none w-full"
                        />
                        <img src={require("../assets/icons/icon-address.png")} alt="icon-address" />
                      </div>                     
                      <label htmlFor="phone-number" className="self-start">No Hp</label>
                      <div className="border-pikkat-gray-2 rounded-lg border-[1.5px] px-3 w-full flex flex-row items-center py-2 ">
                        <input
                        id="phone-number"
                          type="text"
                          value={form.phone}
                          name="phone"
                          onChange={handleChangeInput}
                          placeholder="0815141577672"
                          className=" focus:ring-0 outline-none w-full"
                          autoComplete="off"
                        />
                        <img src={require("../assets/icons/icon-phone.png")} alt="icon-phone" />
                      </div>
                      <label htmlFor="email" className="self-start">Email {'('} <small className="text-blue-600 dark:text-green-500 hover:underline">digunakan sebagai user login</small> {')'}</label>
                      <div className="border-pikkat-gray-2 rounded-lg border-[1.5px] px-3 w-full flex flex-row items-center py-2 ">
                        <input
                        id="email"
                          type="email"
                          value={form.email}
                          name="email"
                          onChange={handleChangeInput}
                          placeholder="agungsanjaya@mail.com"
                          className=" focus:ring-0 outline-none w-full"
                          autoComplete="off"
                        />
                        <img src={require("../assets/icons/envelope-2.png")} alt="icon-envelope-email" />
                      </div>
                      <label htmlFor="set-pass" className="self-start">Password</label>
                      <div className="border-pikkat-gray-2 rounded-lg border-[1.5px] px-3 w-full flex flex-row items-center py-2">
                        <input
                            id="set-pass"
                            type={type}
                            name="password"
                            placeholder="*****"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className=" focus:ring-0 outline-none w-full"
                        />
                        <span className="flex justify-around items-center cursor-pointer" onClick={handleToggle1}>
                            {icon1}
                        </span>
                      </div>
                      <label htmlFor="set-confirm" className="self-start">Confirm Password</label>
                        <div className="border-pikkat-gray-2 rounded-lg border-[1.5px] px-3 w-full flex flex-row items-center py-2">
                            <input
                                id="set-confirm"
                                type={type2}
                                name="confirm"
                                placeholder="*****"
                                value={confirm}
                                onChange={(e) => setConfirm(e.target.value)}
                                className=" focus:ring-0 outline-none w-full"
                            />
                            <span className="flex justify-around items-center cursor-pointer" onClick={handleToggle2}>
                               {icon2}
                            </span>
                        </div>                      
                        <div className="flex items-center py-2">
                          <p className="ms-2 text-sm font-medium text-gray-900">Dengan Mendaftar, Saya menyetujui <a href="#" className="text-blue-600 dark:text-green-500 hover:underline">Syarat dan Ketentuan</a></p>
                        </div>
                      <button 
                        type="button"
                        className={
                            isProcessing
                            ? " cursor-wait pik-btn w-[100%]"
                            : "font-semibold text-white bg-pikkat-green px-16 py-2 rounded-lg my-2"
                        }
                        onClick={handleLogin}
                        disabled={isProcessing}
                      >
                        Daftar
                      </button>
                      <p className="text-pikkat-gray-2 text-nowrap text-sm pt-2 pb-4">
                        Sudah memiliki akun?{" "}
                        <a href="https://admin.pikkat.com/supplier/dashboard" target="blank" className="text-pikkat-green cursor-pointer">
                          Login
                        </a>
                      </p>
                    </div>
                    </div>
                </div>
            </div>           
          </div>
    </div>
  );
};

export default DaftarPemasok;
