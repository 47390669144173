import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import reviewsDummy from "../../assets/dummy/data/generateReviews";
import { FreeMode, Navigation, Pagination } from "swiper/modules";
import "../../assets/style/swiper-custom.css";
import  { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../../constants/global"

export default function Testimony() {
  const [profileData, setProfileData] = React.useState([]);

  React.useEffect(() => {
    setProfileData(reviewsDummy);
  }, []);

  React.useEffect(() => {
    const swiper = document.querySelector(".mySwiper").swiper;
    swiper.params.navigation.prevEl = ".custom-prev";
    swiper.params.navigation.nextEl = ".custom-next";
    swiper.navigation.init();
    swiper.navigation.update();
  }, [profileData]);

  const [visiMisi, setVisiMisi] = useState("");
  
  useEffect(() => {
    axios.post(`${base_url}/home_page`)
      .then(response => {
        setVisiMisi(response.data.video[0]['value_setting']);
      })
      .catch(error => {
        console.error('Error fetching the posts:', error);
      });
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-white bg-gradient-to-tr from-pikkat-green/20 from-[5%] to-[50%] to-white">
      <div className="text-center flex flex-col md:px-40 md:py-10 gap-6">
        <h2 className="text-lg md:text-3xl font-semibold">
          Ulasan{" "}
          <span className="text-pikkat-green">Pelanggan & Mitra Kami</span>
        </h2>
        <p className="light-text text-lg self-center md:max-w-[60%]">
          Sepenggal cerita pengalaman dari Mitra dan Pelanggan tentang Pelayanan Pikkat
        </p>
      </div>
      <div className="md:grid grid-cols-12 py-5 pb-32 container items-center">
        <div className="col-span-5 flex px-4">
          <iframe
            width="100%"
            src={visiMisi}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="rounded-2xl h-[250px] md:h-[343px]"
          ></iframe>
        </div>
        <div className="col-span-7 flex flex-col gap-5 md:px-4 text-center">
          {/* <img src={require("../../assets/dummy-carousel.png")} alt="dummy" /> */}
          <div className="w-[100%] h-[433px]p-10">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              freeMode={false}
              navigation={{
                prevEl: ".custom-prev",
                nextEl: ".custom-next",
              }}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination, Navigation]}
              className="mySwiper bg-cover swiper"
            >
              {profileData.length !== 0 &&
                profileData.map((item) => {
                  return (
                    <SwiperSlide>
                      <div key={item.id}
                        className={`bg-[${item.bgColor}] flex justify-center items-center rounded-lg group m-4 md:m-8`}
                      >
                        <img
                        key={item.id}
                          src={item.src}
                          alt="items"
                          className=" border-2 border-pikkat-green  rounded-xl h-[220px] md:h-[433px] object-contain"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              <div className="custom-prev">
                <img
                  src={require("../../assets/icons/arrow-right-black.png")}
                  className="rotate-180"
                  alt="left arrow"
                />
              </div>
              <div className="custom-next">
                <img
                  src={require("../../assets/icons/arrow-right-black.png")}
                  alt="right arrow"
                />
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
