import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import base_url from "../../constants/global"

export default function FAQ() {  

  const [faqs, setFaqs] = useState({})

  useEffect(() => {
    axios.post(`${base_url}/faqs_pemasok`)
      .then(response => {
        setFaqs(response.data.list_faq);
      })
      .catch(error => {
      });
  }, []);
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="flex justify-center">
      <div className="container">
        <div className="xl:pl-0 md:p-12 md:py-24 flex flex-col md:flex-row">
          <div className="flex-1 flex flex-col justify-center md:pr-10 items-center">
            <h1 className="font-bold text-lg md:text-2xl">
              Frequently Asked{" "}
              <span className="text-pikkat-green">Questions</span>
            </h1>
            <div className="w-full mx-auto p-4 pt-8 flex flex-col gap-3 text-center items-center">
              <button
                className="max-w-[230px] text-sm px-2 py-1 shadow-md text-pikkat-green font-medium flex flex-row gap-2 items-center rounded-md justify-center mb-12"
                onClick={() => navigateTo("/dukungan")}
              >
                Pelajari Lebih Lanjut
                <span>
                  <img
                    src={require("../../assets/icons/up-right-arrow.png")}
                    alt="up right arrow"
                  />
                </span>
              </button>
              {faqs.length > 0 ? (faqs.map((faq, index) => (
                <div key={index} className="mb-4 min-w-full">
                  <button
                    onClick={() => handleClick(index)}
                    className="min-w-full flex flex-row justify-between text-md md:text-md items-center text-pikkat-black bg-transparent text-start rounded-lg font-semibold min-h-[2.8em] px-4 py-6 focus:outline-none"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 4px",
                    }}
                  >
                    {faq.question}
                    <span>
                      <img
                        src={require("../../assets/icons/down-arrow.png")}
                        alt="down arrow"
                        className={openIndex === index ? " rotate-180" : ""}
                      />
                    </span>
                  </button>

                  <CSSTransition
                    in={openIndex === index}
                    timeout={300}
                    classNames="faq"
                    unmountOnExit
                  >
                    <div className="mt-2 p-4 border bg-pikkat-green text-md md:text-md text-left text-white rounded-xl">
                      {faq.answer}
                    </div>
                  </CSSTransition>
                </div>)
              )): (<div>no data</div>)}
            </div>
          </div>
          <div className="flex-1">
            <img
              src={require("../../assets/faq-pemasok.png")}
              className="max-h-[1000px]"
              alt="faq"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
