
const MitraPemasok = () => {
   
    return (
      <div className="bg-gradient-to-br from-[20%] via-[30%] to-[90%] from-pikkat-green/5 via-white to-pikkat-green/10 pb-16">
        <div className="container mx-auto py-12">
          <div className="flex flex-col items-center justify-center space-y-3">
            <h1 className="text-[24px] md:text-[32px] font-semibold">
              Mitra <span className="text-pikkat-green">Pemasok</span>
            </h1>
            <p className="lg:w-[80%] px-2 text-justify lg:text-center text-pikkat-gray-2 lg:px-32 text-lg pt-4">
            Pikkat adalah retailer yang menyediakan produk-produk untuk warung dan toko yang memiliki jaringan dan cakupan distribusi yang luas dan terpercaya. Pikkat mengajak para pemasok dan produsen bersama-sama untuk saling berdagang dan memberikan layanan terbaik bagi pelanggan.
              </p>
          </div>
          <div className="flex flex-col-reverse lg:grid grid-cols-12 gap-16 lg:gap-6 mt-[60px]">
              <div className="col-span-6 grid items-center px-4 lg:px-0 lg:pr-12 lg:pl-4 gap-3">
                <div className="flex flex-row gap-3 hover:text-[16px] hover:p-5 bg-white rounded-lg text-md p-3 border-[1.5px] text-pikkat-gray/90 hover:text-pikkat-black hover:font-medium border-transparent shadow-sm hover:border-pikkat-green hover:bg-pikkat-green/10 duration-200">
                  <p className="text-3xl">.</p>
                  <p className="">
                  Dengan database lebih dari 20.000 toko kecil dan kehadiran yang kuat di beberapa kota di Indonesia, pikkat memberikan akses langsung bagi pemasok dan produsen ke jaringan distribusi yang luas dan mapan.
                  </p>
                </div>
                <div className="flex flex-row gap-3 hover:text-[16px] hover:p-5 bg-white rounded-lg text-md p-3 border-[1.5px] text-pikkat-gray/90 hover:text-pikkat-black hover:font-medium border-transparent shadow-sm hover:border-pikkat-green hover:bg-pikkat-green/10 duration-200">
                  <p className="text-3xl">.</p>
                  <p className="">
                  Konsep toko Pikkat dirancang dengan inovatif untuk memenuhi kebutuhan pelanggan yang terus berkembang, memberikan konsep platform baru dan menarik bagi pemasok dan produsen untuk memamerkan produk-produknya.
                  </p>
                </div>
                <div className="flex flex-row gap-3 hover:text-[16px] hover:p-5 bg-white rounded-lg text-md p-3 border-[1.5px] text-pikkat-gray/90 hover:text-pikkat-black hover:font-medium border-transparent shadow-sm hover:border-pikkat-green hover:bg-pikkat-green/10 duration-200">
                  <p className="text-3xl">.</p>
                  <p className="">
                  Pikkat bekerja sama dengan perusahaan dan koperasi besar dalam mengelola toko ritel mereka untuk memiliki keunggulan kompetitif di pasar. Kemitraan strategis ini berarti memberikan jangkauan pasar ke konsumen yang lebih luas.
                  </p>
                </div>
                <div className="flex flex-row gap-3 hover:text-[16px] hover:p-5 bg-white rounded-lg text-md p-3 border-[1.5px] text-pikkat-gray/90 hover:text-pikkat-black hover:font-medium border-transparent shadow-sm hover:border-pikkat-green hover:bg-pikkat-green/10 duration-200">
                  <p className="text-3xl">.</p>
                  <p className="">
                  Salah satu pendiri Pikkat memiliki pengalaman yang luas di industri ritel dan telah bekerja dengan perusahaan-perusahaan ternama termasuk Delhaize Group. Keahlian ini memastikan bisnis Pikkat dipandu oleh strategi yang telah terbukti dan wawasan industri yang mendalam.</p>
                </div>
              </div>
              <div className="col-span-6 object-contain relative flex items-center">
                <img
                  className="scale-75 lg:scale-100 hidden lg:block absolute bottom-0 -left-12 z-20"
                  alt="background"
                  src={require("../../assets/icons/rectangle.png")}
                />
                <img
                  className="scale-75 scale lg:scale-100 hidden lg:block absolute top-0 right-0 z-20"
                  alt="background"
                  src={require("../../assets/icons/rectangle.png")}
                />
                <img
                  src={require("../../assets/mitra-pemasok.png")}
                  alt="tujuan"
                  // className="scale-[90%] top-16 z-30"
                />
              </div>
            </div>
        </div>
      </div>
    );
  };
  
  export default MitraPemasok;
  

