import LoginPage from "../pages/LoginPage"
import LandingPage from "../pages/LandingPage"
import AboutUs from "../pages/TentangKami"
import DaftarSekarang from "../pages/DaftarSekarang"
import DaftarToko from "../pages/DaftarToko"
import DaftarPemasok from "../pages/DaftarPemasok"
import DaftarMember from "../pages/DaftarMember"
import StorePartner from "../pages/MitraToko"
import SupplierPartner from "../pages/MitraPemasok"
import Berita from "../pages/Berita"
import DetailBerita from "../pages/DetailBerita"
import Dukungan from "../pages/Dukungan"
import PrivacyPolicy from "../pages/PrivacyPolicy"

const router = [
    {
        exact: true,
        path: "/",
        element: (
            <LandingPage/>
        )
    },
    {
        exact: true,
        path: "login",
        element: (
            <LoginPage/>
        )
    },
    {
        exact: true,
        path: "daftar-mitra-toko",
        element: (
            <DaftarToko/>
        )
    },
    {
        exact: true,
        path: "daftar-mitra-pemasok",
        element: (
            <DaftarPemasok/>
        )
    },
    {
        exact: true,
        path: "daftar-member",
        element: (
            <DaftarMember/>
        )
    },
    {
        exact: true,
        path: "daftar-sekarang",
        element: (
            <DaftarSekarang/>
        )
    },
    {
        exact: true,
        path: "privacy",
        element: (
            <PrivacyPolicy/>
        )
    },
    {
        path: "/about",
        element: (
            <AboutUs />
        )
    },
    {
        path: "/mitra-toko",
        element: (
            <StorePartner />
        )
    },
    {
        path: "/mitra-pemasok",
        element: (
            <SupplierPartner />
        )
    },
    {
        path: "/dukungan",
        element: (
            <Dukungan />
        )
    },
    {
        path: "/berita",
        element: (
            <Berita />
        )
    },
    {
        path: "/detail-berita/:id",
        element: (
            <DetailBerita />
        )
    },
]

export default router
