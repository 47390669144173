import React from "react";
import Artwork from "../../assets/artwork.png";
import pemasok1 from "../../assets/pemasok-icons1.svg";
import pemasok2 from "../../assets/pemasok-icons2.png";
import pemasok3 from "../../assets/pemasok-icons3.png";
import pemasok4 from "../../assets/pemasok-icons4.png";

const MitraPemasok = () => {
  return (
    <div className="bg-gradient-to-br from-pikkat-green/10 to-pikkat-green/5 via-white">
      <div className="flex flex-col px-2 lg:px-24 pt-12 lg:pt-10 lg:pb-24">
        <div className="flex flex-col justify-center items-center lg:w-[59%] self-center">
          <h1 className="font-semibold text-3xl mb-10">
            <span className="primary-color">Mitra</span> Pemasok
          </h1>
          <p className="text-center text-lg text-slate-500">
          Supply barang siap antar dari pesanan Mitra Pikkat di setiap kota
          </p>
        </div>
        <div className="lg:grid grid-cols-2 lg:pt-24  lg:gap-10">
          <div className="flex justify-center items-center">
            <img src={Artwork} className="lg:absolute z-10" alt="artwork" />
          </div>
          <div className="flex flex-col gap-4 lg:grid grid-cols-2 lg:gap-10 mt-36 lg:-mt-12">
            <div className="flex justify-center items-center">
              <div className="border-2 flex flex-wrap flex-col border-white-200 hover:border-pikkat-green py-10 px-6 rounded-xl w-[100%] h-[100%] shadow-md">
              <div className="mb-2"><img src={pemasok1} className="z-10" alt="banyak-mitra" /></div>
                <p className="mb-2 font-semibold text-md">Banyak Mitra Toko</p>
                <p className=" text-slate-500 lg:text-justify hyphens-auto text-sm">
                Mitra toko yang tersebar di berbagai wilayah dan siap berbelanja mellui aplikasi POS Pikkat untuk mitra toko.
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="border-2 flex flex-wrap flex-col border-white-200 hover:border-pikkat-green  py-10 px-6 flex rounded-xl w-[100%] h-[100%] shadow-md">
              <div className="mb-2"><img src={pemasok2} className="z-10" alt="banyak-mitra" /></div>
                <p className="mb-2 font-semibold text-md">Pembayaran Aman</p>
                <p className=" text-slate-500 lg:text-justify hyphens-auto text-sm">
                Sistem pembayaran yang terpantau melalui aplikasi dari mitra toko yang berbelanja ke pemasok.
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="border-2 flex flex-wrap flex-col border-white-200 hover:border-pikkat-green  py-10 px-6 flex rounded-xl w-[100%] h-[100%] shadow-md">
              <div className="mb-2"><img src={pemasok3} className="z-10" alt="banyak-mitra" /></div>
                <p className="mb-2 font-semibold text-md">
                  Aplikasi Real Time
                </p>
                <p className=" text-slate-500 lg:text-justify hyphens-auto text-sm">
                aplikasi dengan pelayanan full time sebagi alat pantau penjualan produk di mitra toko.
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="border-2 flex flex-wrap flex-col border-white-200 hover:border-pikkat-green  py-10 px-6 flex rounded-xl w-[100%] h-[100%] shadow-md">
              <div className="mb-2"><img src={pemasok4} className="z-10" alt="banyak-mitra" /></div>
                <p className="mb-2 font-semibold text-md">Pesanan Stabil</p>
                <p className=" text-slate-500 lg:text-justify hyphens-auto text-sm">
                Pesanan produk yang stabil karena kita sudah memiliki ribuan member loyal dari berbagai kota
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MitraPemasok;
