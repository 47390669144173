import icon1 from "../../assets/icons/layanan-pemasok1.png";
import icon2 from "../../assets/icons/layanan-pemasok2.png";
import icon3 from "../../assets/icons/layanan-pemasok3.png";


const Component1 = () => {
  const images = [icon1, icon2, icon3];  
  const titles = ["Aplikasi Mitra Pemasok", "Otomatis Online", "Garansi Produk"];
  const contents = [
    "Mitra Pemasok akan mendapatkan dashboard Mitra yang dapat diakses untuk kebutuhan memasok barang kepada Mitra Toko.",
    "Dengan menjadi Mitra Pemasok Pikkat, otomatis layanan menjadi online untuk memudahkan mitra toko untuk menemukan produk yang di inginkan dan lokasi Mitra Toko terdekat",
    "Garansi perputaran penjualan produk yang stabil dan meningkatkan supply dan penjualan kepada ribuan Mitra Toko."
   ];
  return (
    <div className="bg-gradient-to-br from-pikkat-green/10 via-white to-pikkat-green/20 via-[40%] to-[90%] flex justify-center items-center py-6 lg:py-24">
      <div className="container lg:grid grid-flow-col grid-cols-12">
        <div className="col-span-7 text-justify px-4 lg:px-0 lg:pr-24 flex flex-col justify-around gap-4">
          <div>
            <h1 className="text-[24px] lg:text-[35px] md:text-[38.5px] font-semibold">
              Layanan Pemasok{" "}
              <span className="text-pikkat-green">Lebih Mudah.</span>
            </h1>
            <p className="text-pikkat-gray-2 text-md pt-4">
            Layanan untuk Mitra Pemasok yang menjamin kelancaran perputaran produk dan mudah untuk melihat proyeksi penjualan produk dalam suatu wilayah.
            </p>
          </div>
          <div className="flex flex-col gap-4">
            {images.map((image, index) => (
              <div key={index} className="bg-white p-4 lg:p-8 flex flex-col lg:flex-row gap-8 rounded-3xl border-2 border-transparent hover:border-pikkat-green/80">
              <div className="flex flex-row justify-center lg:flex-none">
                <img src={image} alt="component2" className="h-auto min-w-[100px]" />
              </div>
              <div className="flex flex-col gap-2">
                <h2 className="text-center lg:text-left text-md lg:text-xl font-semibold">{titles[index]}</h2>
                <p className="text-md lg:text-md text-center lg:text-left text-pikkat-gray-2/80">
                  {contents[index]}
                </p>
              </div>
            </div>
            ))}
          </div>
        </div>
        <div className="col-span-5 pl-6 items-center justify-center flex flex-col">
          <img src={require("../../assets/component1.png")} alt="component" />
        </div>
      </div>
    </div>
  );
};

export default Component1;
