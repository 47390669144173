import axios from "axios";
import base_url from "../../constants/global";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const Article = () => {
  const {id} = useParams()
  const [article, setArcile] = useState([]);
  useEffect(() => {
    axios
      .post(`${base_url}/detail_berita`, { id })
      .then((response) => {
        setArcile(response.data.detail);
        console.log(response.data.detail);
        
      })
      .catch((error) => {
        console.error("Error fetching the posts:", error);
      });
  }, [id]);
  return (
    <>
      {article.title ? (
        <div className="flex flex-col" key={id}>
          <img
            src={article.images}
            alt="berita awal"
            className="object-cover min-w-full max-h-[500px] rounded-2xl"
          />
          <div className="article text">
            <h1 className="text-left">{article.title}</h1>
            <div className="flex flex-row gap-5 text-xs text-pikkat-gray-2/80 items-center ">
              <p>{article.admin}</p>
              <img
                src={require("../../assets/icons/dot.png")}
                alt="just dot"
                className=" w-auto self-center"
              />
              <p>{article.updated.slice(0, 10)}</p>
              <button className=" text-orange-400 font-semibold border-[1.5px] border-orange-400 bg-orange-200/30 p-1 px-2 rounded-lg">
                {article.categori}
              </button>
            </div>            
            <div dangerouslySetInnerHTML={ { __html: article.content } }></div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <p>loading...</p>
          <img src={require("../../assets/icons/loading.gif")} style={{height:"90px"}} alt="loading-gif"/>
        </div>
      )}
    </>
  );
};

export default Article;
