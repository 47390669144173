import FAQDropdown from "../components/Dukungan/faq-content";
import GetInTouch from "../components/LandingPage/get-in-touch";

export default function Dukungan(){
return(
    <div>
        {/* <img src={require('../assets/dummy/faq-header.png')} alt='dummy'/> */}
        <FAQDropdown/>
        <GetInTouch/>
    </div>
)
} 