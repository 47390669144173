import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";

const BeritaIntroCard = ({ article, index }) => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div
      className="lg:grid grid-flow-col grid-cols-11 items-stretch min-h-[100px] max-h-100 hover:cursor-pointer max-w-[240px] lg:max-w-full bg-white p-2 rounded-lg lg:bg-transparent lg:p-0 gap-5 lg:gap-0 flex flex-col"
      onClick={() => navigateTo(`/detail-berita/${article.id}`)}
    >
      <div className="lg:col-span-2 flex flex-row lg:flex-none justify-center">
        <img
          src={article.images}
          alt="berita card"
          className="min-h-ful min-w-full lg:w-auto lg:object-cover rounded-xl"
        />
      </div>
      <div className="col-span-9 flex flex-col gap-[1px] px-4 text-justify hover:cursor-pointer" >
        <h3 className="text-sm lg:text-md font-semibold">{article.title}</h3>
        <p className="text-xs text-pikkat-gray-2 lg:block hidden">
        <div dangerouslySetInnerHTML={ { __html: article.content.slice(0, 100) } }></div>
        </p>
        <div className="flex flex-row gap-2 lg:text-xs text-nowrap text-pikkat-gray-2/80 items-center text-[11px] px-4 py-2 lg:py-0 lg:px-0">
          <p>{article.admin}</p>
          <img
            src={require("../../assets/icons/dot.png")}
            alt="icon dots"
            className="max-h-[6px] w-auto self-center"
          />
          <p>{article.updated.slice(0, 10)}</p>
          <button className="hidden lg:block text-orange-400 font-semibold border-[1.5px] border-orange-400 bg-orange-200/30 p-1 px-2 rounded-lg">
            {article.categori}
          </button>
        </div>
        <button className=" text-orange-400 font-semibold border-[1.5px] border-orange-400 bg-orange-200/30 p-1 px-2 rounded-lg max-w-[100px] my-2 lg:hidden">
          {article.categori}
        </button>
      </div>
    </div>
  );
};

const BeritaIntro = ({global}) => {

  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      // Adjust this value as needed to make the next content slightly visible
      containerRef.current.scrollLeft = 50;
    }
  }, []);

  return (
    <>
      {global.length > 0 ? (
        <div className="flex flex-col pb-16">
          <div className="flex flex-col pb-8 text-center lg:px-56">
            <h1 className="font-bold text-4xl pb-6">
              Blog &<span className="text-pikkat-green"> Berita</span>
            </h1>
            <p className="text-pikkat-gray-2/80 font-medium">
              Blog dan Berita Seputar kegiatan Pikkat dan Mitra
            </p>
          </div>
          <div className="lg:grid grid-flow-col grid-cols-11">
            <div className="col-span-6 flex flex-col gap-3 lg:p-0 bg-white lg:pr-8 hover:cursor-pointer" onClick={() => navigateTo(`/detail-berita/${global[0].id}`)}>
              <img
                src={global[0].images}
                alt="intro berita"
                className="max-h-[350px] object-cover rounded-2xl"
              />
              <h1 className="font-semibold text-lg px-2 lg:px-0 lg:text-3xl">
                {global[0].title}
              </h1>
              <p className="text-pikkat-gray-2 lg:block hidden text-sm">
              <div dangerouslySetInnerHTML={ { __html: global[0].content.slice(0, 150) } }></div>
              </p>
              <div className="flex flex-row gap-5 text-xs text-pikkat-gray-2 items-center px-2 lg:px-0">
                <p>{global[0].admin}</p>
                <img
                  src={require("../../assets/icons/dot.png")}
                  alt="just dot"
                  className="max-h-[6px] w-auto self-center"
                />
                <p>{global[0].updated.slice(0, 10)}</p>
                <button className=" text-orange-400 font-semibold border-[1.5px] border-orange-400 bg-orange-200/30 p-1 px-2 rounded-lg">
                  {global[0].categori}
                </button>
              </div>
            </div>

            <div className="col-span-5 px-3 border-l-[1px] border-pikkat-gray-2/20 my-4 flex flex-col gap-3">
              <p className=" text-blue-400 font-semibold text-xs flex flex-row items-center gap-2">
                <span>
                  <img
                    src={require("../../assets/icons/red-dot.png")}
                    alt="red dot"
                  />
                </span>
                Blog & Berita Serupa
              </p>
              <div
                className="flex flex-row lg:flex-col overflow-x-scroll gap-5 lg:gap-1 no-scrollbar"
                ref={containerRef}
              >
                {global.slice(0, 5).map((article, index) => (
                  <BeritaIntroCard key={index} article={article} index={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center min-w-full min-h-screen">
          <p>loading...</p>
          <img src={require("../../assets/icons/loading.gif")} style={{height:"90px"}} />
        </div>
      )}
    </>
  );
};

export default BeritaIntro;
